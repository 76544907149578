import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { NavLink, useNavigate, useLocation} from "react-router-dom";
import Notiflix from 'notiflix';
import { doc } from 'firebase/firestore';

const Help = () => {

    const validationSchema = Yup.object({
        userquery: Yup.string().required('*Please enter your query').required(),
      });
      const formOptions = { resolver: yupResolver(validationSchema) };
      const { register, handleSubmit, formState } = useForm(formOptions);
      const { errors } = formState;
      const [user, loading, error] = useAuthState(auth);
      const location = useLocation();
      const [formLoading, setFormLoading] = useState(null);

      const navigate = useNavigate();

      const onSubmit = async (e) => {
        // handleSendIvite({userName,useremailId});
      }
      window?.Usersnap?.logEvent('feedback_submit');
    //   var widget;
    const [widget, setWidget] = useState();
      setTimeout(()=>{
            const widget = document?.querySelector('us-widget');
            setWidget(widget);
      },[1000]);
     
      useEffect(()=>{
        if(widget){
            setFormLoading(true);
            setTimeout(()=>{
                widget.style.display = "block";
                setFormLoading(false);
            },[1000]);
          }
      },[widget])

      const shadowRoot = widget?.shadowRoot;
      useEffect(()=>{
        var appRoot; 
        var formwp;
        var formdiv;
        var formChild;
        var closeBtn;
        if(shadowRoot){
            
            appRoot = shadowRoot.querySelector('#appRoot');
            appRoot.style.zIndex = '10';
            formwp = appRoot.children[2];

            if(formwp){
                formwp.style.position = "inherit";
                formwp.style.left = "27.2vw";
                formwp.style.top = "0%";
                formwp.style.transform = 'translateY(140px)' ;
                formwp.style.width = "fit-content"
                formwp.style.height = "auto"
                formdiv = formwp.children[0];
                
                formdiv.style.position = "inherit"
                formdiv.style.top = "0%"
                formdiv.style.left = "0%"
                formdiv.style.width = "75vw"
                formdiv.style.boxShadow = "none"
                formdiv.style.paddingBottom = "130px"
                formdiv.style.overflowY = "auto"
                formdiv.querySelector('form').style.border = "1px solid #80808061"
                // formdiv.querySelector('form').style.width = "92%"
                document.querySelector('#feedback-form').src = widget.shadowRoot;

                formdiv.querySelector('form')?.addEventListener('submit', (event) => {
                    event.preventDefault();
                    setTimeout(()=>{
                        if(document?.querySelector('us-widget')){
                            // setTimeout(()=>{
                            //     navigate('/account')
                            // },[3000])
                        }
                        else {
                            navigate('/account')
                        }
                    },[5000]);
                });
                formChild = formdiv.children;
                

                for(let i=0; i<formChild.length; i++){
                    formChild[i].style.width = "92.5%"
                }

                var formHead = formdiv.querySelector('h1');

    
                formHead.innerText ="Send us your query";
                formHead.style.width = "500px"
                formHead.style.textAlign = "left"
                formHead.style.borderBottom = "none"
                formHead.style.fontWeight = "600"
                formHead.style.padding = "5px 0px 0px 0px"

                closeBtn = formdiv.lastChild
                closeBtn.style.opacity ="0"

                var pElement = document.createElement("p");
                pElement.style.fontSize = "15px"
                pElement.style.color = "#5A5A61"
                pElement.style.fontWeight = "500"
                pElement.style.margin = "0px"
                pElement.style.padding = "20px 0px"
                pElement.style.backgroundColor = "#fff"
                pElement.textContent = "We will contact you via email in the next 1 - 2 business days.";
                formdiv.insertBefore(pElement, formdiv.querySelector("h1").nextSibling);

                var formpara = formdiv.querySelectorAll("p");

                for(let i=1; i<formpara.length; i++){
                    formpara[i].style.display = "none"
                }

                if (window.innerWidth < 768) {
                        formwp.style.left = "0vw";
                        formwp.style.top = "2%";
                        formdiv.style.bottom = "130px"
                        formdiv.style.marginBottom = "130px"
                        formdiv.style.paddingBottom  = "100px";
                        formdiv.style.backgroundColor = "#fff"
                        formdiv.style.width = "100vw"
                        for(let i=0; i<formChild.length; i++){
                            formChild[i].style.width = "auto"
                    }
                    formHead.style.padding = "20px 20px 0px 20px"
                    pElement.style.padding = "20px 20px"
                }
                if(window.innerWidth > 768 && window.innerWidth < 800){
                    formdiv.style.width = "55vw"
                }
                if (window.innerWidth > 767 && window.innerWidth < 900) {
                    formwp.style.left = "315px";
                    formdiv.style.width = "55vw"
                    for(let i=0; i<formChild.length; i++){
                        formChild[i].style.width = "auto"
                    }
                }
                if(window.innerWidth > 800 && window.innerWidth < 900){
                    formdiv.style.width = "58vw"
                }
                if (window.innerWidth > 900 && window.innerWidth < 1000) {
                    formwp.style.left = "320px";
                    formdiv.style.width = "61vw"
                    for(let i=0; i<formChild.length; i++){
                        formChild[i].style.width = "auto"
                    }
                }
                if (window.innerWidth > 1000 && window.innerWidth < 1199) {
                    formwp.style.left = "330px";
                    formdiv.style.width = "64vw"
                    for(let i=0; i<formChild.length; i++){
                        formChild[i].style.width = "auto"
                    }
                }
            }
        }

        
      },[widget, shadowRoot, location.pathname]);

      
    return (
        <div className='w-[100%] h-full lg:w-[94%] px-3 lg:mx-[20px] bg-[#F5F5F5] sm:bg-[#fff] relative'>
            <Helmet>
                <title>Brainstormer Help and Support</title>
            </Helmet>
            {user ?
            <div className='help-support-wrapper'>
                <div className="help-support-inner">
                    <div className="breadcrumbs-ct hidden sm:block my-4">
                        <ul className='flex items-center'>
                            <li className='text-[14px] list-none text-[#5A5A61] mr-[7px] font-semibold cursor-pointer'><Link to={'/'}>Home</Link></li>/
                            <li className='text-[14px] list-none text-[#5A5A61] mx-[7px] font-semibold cursor-pointer'><Link to={'/account'}>My Profile</Link></li>/
                            <li className='text-[14px] list-none text-[#252525] mx-[7px] font-semibold'>Help & support</li>
                        </ul>
                    </div>
                    <div className="mob-heading flex justify-start items-center my-[14px] sm:hidden">
                        <Link className="ml-[-10px]" to="/account"><img src="/assets/images/leftArrow.svg" alt="left-arrow-icon" /></Link>
                        <h2 className='font-bold text-[20px]'>Help & Support</h2>
                    </div>
                    {/* <h2 className='text-[#252525] text-[20px] font-bold mb-[8px]'>Send us your query</h2> */}
                    {/* <p className='text-[#5A5A61] text-[15px] font-semibold max-w-[340px]'>We will contact you via email  in the next 1 - 2 business days.</p> */}
                    {/* <form action="" className='flex flex-col justify-end'>
                        <textarea name="query" id=""  rows="5" maxLength="5000" className='w-full border border-[#DDDFE5] my-2 px-2' {...register('userquery')}>

                        </textarea>
                        <span className='text-red-500 sm:text-sm'>{errors.userquery?.message}</span>
                        <button onClick={handleSubmit(onSubmit)} className='w-[110px] lg:w-[200px] xl:w-[300px] bg-[#2C2D98] rounded-[6px] h-[42px] flex justify-center items-center font-semibold hover:bg-opacity-75 mt-[12px] p-0 text-[18px] self-end'>Send</button>
                    </form> */}
                    <div className='flex justify-between items-center mt-[40px]'>
                        {/* <div className='w-[65%] sm:w-[70%] lg-w-auto'>
                            <h2 className='text-[#252525] text-[20px] font-bold mb-[8px]'>Send us your query</h2>
                            <p className='text-[#5A5A61] text-[15px] font-semibold'>We will contact you via email  in the next 1 - 2 business days.</p>
                        </div> */}
                        {/* <button onClick={()=>{window.Usersnap.logEvent('feedback_submit')}} id="send-enquiry" className='w-[110px] lg:w-[200px] xl:w-[300px] bg-[#2C2D98] rounded-[6px] h-[42px] flex justify-center items-center font-semibold hover:bg-opacity-75 p-0 px-[0px] md:text-[18px]'>Send</button> */}
                    </div>
                    <div id="feedback-container" className='relative'>
                        <iframe id="feedback-form" src='' className='absolute' width="50%" height="500"></iframe>
                    </div>
                    {/* <div className='w-full my-[30px]'>
                        <span className='text-[#5A5A61] text-[18px] text-center w-full block my-4 text-bold'>or</span>
                    </div> */}
                    {/* <div className='flex justify-between items-center'>
                        <div className='w-[65%] sm:w-[70%] lg-w-auto'>
                            <h2 className='text-[#252525] text-[20px] font-bold mb-[8px]'>Talk to our Support Bot</h2>
                            <p className='text-[#5A5A61] text-[15px] font-semibold'>Talk to our Brainstormer support bot 24 x 7</p>
                        </div>
                        <button className='w-[110px] lg:w-[200px] xl:w-[300px] bg-[#2C2D98] rounded-[6px] h-[42px] flex justify-center items-center font-semibold hover:bg-opacity-75 p-0 px-[0px]'>Chat Now</button>
                    </div> */}
                </div>
            </div>:!loading? <p className='m-[15px] sm:my-[25px] sm:ml-[20px] lg:ml-[0px] pl-0 relative left-[-8px] text-[#5a5a61] font-semibold'>You are not logged In! Please <Link to="/login" className='text-[#2C2D98] font-semibold underline'>Login</Link> and try again</p>:null}
            {
                formLoading != false ? 
                 <div className="loader-ct h-[100%] absolute top-[0px] left-0">
                    <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader' loading="lazy" className='relative bottom-[20px]'></img>   
                </div>:null
            }  
        </div>
    )
}

export default Help