import React from 'react'
import { json, useNavigate, BrowserRouter as Router } from "react-router-dom";
import Highlight from './Highlight';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useState, useEffect } from 'react';

const CreatorCard = ({ data, highlight }) => {

    const [user] = useAuthState(auth);

    const navigate = useNavigate();

    const creatorData = data.attributes ? data.attributes : data;
    const profileImg = data?.attributes?.ProfileImage?.data?.attributes ? data?.attributes?.ProfileImage?.data?.attributes : data?.ProfileImage

    function openFeatureCreator(name) {
        navigate(`/creatorprofile/${name}`);
    }

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
          return text.substring(0, maxLength - 1)+'...';
        } else {
          return text;
        }
      }
      
      const [ppState,setPpstate] = useState(false);
      const [deviceType, setDeviceType] = useState('');
      const [browserType, setBrowserType] = useState('');
      
      useEffect(()=>{
          if((("standalone" in window.navigator) && !window.navigator.standalone) || ( !window.matchMedia('(display-mode: standalone)').matches)){
              setPpstate(true)
          }
          const userAgent = navigator.userAgent;
          if (/Android/i.test(userAgent)) {
              setDeviceType('Android');
          } 
          else if (/iPhone/i.test(userAgent)) {
              setDeviceType('IOS');
              const userAgent = navigator.userAgent.toLowerCase();
              if (userAgent.indexOf('chrome') > -1) {
                  setBrowserType('ios-chrome');
              }  else if (userAgent.indexOf('safari') > -1) {
                  setBrowserType('ios-safari');
              } 
          } else if (/Windows Phone/i.test(userAgent)) {
              setDeviceType('Windows Phone');
          } else {
              setDeviceType('Other');
          }
      },[])


    return (
        <div className={`${user ? 'w-[48%]' : 'w-[32%]'} bg-[#fff] featured-card creator-card flex items-start px-4 pt-3 pb-4 border rounded-md h-auto sm:mx-1 sm:mr-2 my-2 md:my-4 flex-col relative mr-[15px]`}>
            <div className="img-ct top-sec flex w-full flex-row items-center mb-4">
                <div className="flex items-center justify-center flex-col relative min-w-[60px] img-content-ct">
                    <div className="img-innet-ct">
                        <img className=" mb-3 rounded-full shadow-lg" src={profileImg?.url ? "https://studio.brainstormer.io" + profileImg?.url : 'assets/images/app_dark_img.png'} alt="Bonnie image" />
                    </div>
                    {creatorData?.bots?.data ? <span className="bot-no py-[1px] px-[5px] text-[12px] bg-[#F5F5F5] rounded-[10px] absolute bottom-[-10px] left-[0px] min-w-[60px] flex justify-center text-[#7C7C85]">{creatorData?.bots?.data?.length + ` Bot${creatorData?.bots?.data?.length > 1 ? 's' : ''}`}</span> : null}
                </div>
                <h5 className="ml-2 sm:ml-3 text-[#2C2D98] md:text-[18px] font-bold text-left whitespace-normal">
                    {/* <Highlight text={creatorData?.Name} highlight={highlight} /> */}
                    {creatorData?.Name}
                </h5>
            </div>

            <div className="content-ct h-full flex flex-col justify-between">
                <p className='description text-left font-semibold text-sm 
            text-[#5A5A61] mb-[10px] sm:text-left'>
                    {/* <Highlight text={creatorData?.Description} highlight={highlight} /> */}
                    {creatorData && (deviceType == "IOS" || deviceType == "Android" || deviceType == "Windows Phone") ? truncateText(creatorData?.Description, 150) : truncateText(creatorData?.Description, 230) }
                </p>
                <div className="flex my-2 space-x-3 w-full ">
                    <a className="start-convr-link w-full inline-flex cursor-pointer items-center justify-center px-4 py-3 text-xs font-medium text-center text-white bg-[#2C2D98] rounded-lg hover:bg-blue-800  dark:focus:ring-blue-800" onClick={() => openFeatureCreator(creatorData?.Slug)}>
                        View Profile
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CreatorCard