import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { useState, useEffect } from 'react';
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
  } from "firebase/firestore";
  import { NavLink, useNavigate, useLocation} from "react-router-dom";
  import Notiflix from 'notiflix';
  import axios from "axios";
  import { SENDBIRD_INFO } from '../constants/constants';

const Account = () => {
    
    const [user, loading, error] = useAuthState(auth);
    const [userData, setUserdata] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    console.log(loading);

    if(loading == true){
        Notiflix.Loading.standard();
    }
    else {
        Notiflix.Loading.remove();
    }

    const fetchUserdata = async () => {
        const data = query(collection(db, "Users"), where('uid','==',user.uid));
      getDocs(data).then((doc)=>{
           setUserdata(doc?._snapshot?.docs?.keyedMap?.root?.value?.data?.value?.mapValue?.fields);
        })
    }

    useEffect(()=>{
        // console.log(user.uid);
        if(user){
            fetchUserdata();
        }
    },[location.pathname,user]);

    const deleteUserdata = async () => {
        Notiflix.Confirm.show(
            "Confirm",
            "Are you sure you would like to delete your account?",
            "Yes",
            "No",
            async () => {
                Notiflix.Loading.standard();
                const headers = {
                    'Content-Type':'multipart/form-data',
                    'Api-token': SENDBIRD_INFO.apiToken,
                };
                if (user) {
                    const usertoken = await user.getIdToken();
                    headers.Authorization = `Bearer ${usertoken}`;
                }
                await axios({
                    url:`https://storm.brainstormer.io/delete_user`,
                    method: 'post',
                    headers: headers,
                })
                .then((res) => res)
                .then((response) => {
                    Notiflix.Notify.success('Account Deleted Successfully');
                    Notiflix.Loading.remove();
                    navigate('/login');
                })
                .catch((err) => {
                    Notiflix.Loading.remove();
                });
             
            }
            
        )
        
    }

  return (
    <div className='w-[100%] h-fit lg:w-[100%] bg-[#F5F5F5] sm:bg-[#fff] pb-[100px] sm:pb-[0px]'>
        <Helmet>
            <title>Brainstormer User Account</title>
        </Helmet>
        {user ? 
        <div className="myacount-wraaper">
            <div className="myaccount-inner">
                <div className="account-header bg-[#F5F5F5]  px-3 lg:px-[30px] pb-[20px]">
                    <div className="breadcrumbs-ct hidden sm:block py-2">
                        <ul className='flex items-center'>
                            <li className='text-[14px] list-none text-[#5A5A61] mr-[7px] font-semibold cursor-pointer'><Link to={'/'}>Home</Link></li>/
                            <li className='text-[14px] list-none text-[#252525] mx-[7px] font-semibold'>My Profile</li>
                        </ul>
                    </div>
                    <div className="mob-heading flex justify-start items-center my-[20px] sm:my-[14px] sm:hidden">
                        {/* <Link className="ml-[-10px]" to="/"><img src="/assets/images/leftArrow.svg" alt="left-arrow-icon" /></Link> */}
                        <h2 className='font-bold text-[20px]'>Account</h2>
                    </div>
                    <div className='bot-name-wrapper flex justify-start items-center mt-[10px] sm:my-[15px]'>
                        <div className="img-ct w-[65px] h-[65px] sm:w-[65px] sm:h-[65px] overflow-hidden rounded-[50%]">
                            <img src={userData?.profileImage?.stringValue ? " "+userData?.profileImage?.stringValue : '/assets/images/app_dark_img.png'} alt="bot-img" className='w-full h-full object-cover' /> 
                        </div>
                        <div className='account-name-ct ml-3 flex justify-between items-center max-w-[75%]'>
                            <div className='mr-2 sm:mr-3 max-w-fit'>
                                <h1 className='text-[#252525] text-[20px] sm:text-[20px] font-bold leading-[24px] sm:leading-[24px]'>{userData && " "+userData.name?.stringValue}</h1>
                                <span className='text-[14px] sm:text-[16px] text-[#5A5A61] font-semibold'>by Brainstormer.io</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="account-links-wrapper px-3 lg:px-0 lg:mx-[30px] w-full lg:w-[92%] flex justify-between flex-col lg:flex-row">
                    <div className="general-settings-ct w-full lg:w-[48.5%]">
                        <h2 className='text-[#252525] text-[17px] font-bold mt-[20px] mb-[15px] px-[4px] sm:px-0'>General Settings</h2>
                        <ul className='links px-[15px] list-none divide-y divide-[#E5E5E5] border rounded-[8px] bg-[#fff] mb-[10px]'>
                            {/* <li className=''>
                                <Link to="/account/dashboard" className="px-[10px] py-[20px] flex justify-between items-center">
                                    <span className='flex justify-start items-center'>
                                        <img src="/assets/images/dashboard-icon.svg" alt="dashboard icon" />
                                         <span className='text-[#252525] text-[16px] font-semibold ml-[18px] hover:text-opacity-75'>Dashboard</span>
                                    </span> 
                                    <img src='/assets/images/right-arrow-black.svg'></img>
                                </Link>
                            </li> */}
                            <li className='border-1 border-b-[#E5E5E5]'>
                                <Link to="/account/notification-settings" className="px-[10px] py-[20px] flex justify-between items-center">
                                    <span className='flex justify-start items-center'>
                                        <img src="/assets/images/notification-icon.svg" alt="notification icon" />
                                         <span className='text-[#252525] text-[16px] font-semibold ml-[18px] hover:text-opacity-75'>Notification</span>
                                    </span> 
                                    <img src='/assets/images/right-arrow-black.svg'></img>
                                </Link>
                            </li>
                            <li className='border-1 border-b-[#E5E5E5]'>
                                <Link to="/account/account-settings" className="px-[10px] py-[20px] flex justify-between items-center">
                                    <span className='flex justify-start items-center'>
                                        <img src="/assets/images/editIcon.svg" alt="settting icon" />
                                         <span className='text-[#252525] text-[16px] font-semibold ml-[18px] hover:text-opacity-75'>Account Settings</span>
                                    </span> 
                                    <img src='/assets/images/right-arrow-black.svg'></img>
                                </Link>
                            </li>
                            {/* <li className='border-1 border-b-[#E5E5E5]'>
                                <Link to="/account/subscription" className="px-[10px] py-[20px] flex justify-between items-center">
                                    <span className='flex justify-start items-center'>
                                        <img src="/assets/images/subscription-icon.svg" alt="feedback icon" />
                                         <span className='text-[#252525] text-[16px] font-semibold ml-[18px] hover:text-opacity-75'>Subscription</span>
                                    </span> 
                                    <img src='/assets/images/right-arrow-black.svg'></img>
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                    <div className="support-settings-ct  w-full lg:w-[48.5%]">
                        <h2 className='text-[#252525] text-[17px] font-bold mt-[20px] mb-[15px] hover:text-opacity-75 px-[4px] sm:px-0'>Support Settings</h2>
                        <ul className='links px-[15px] list-none divide-y divide-[#E5E5E5] border rounded-[8px] bg-[#fff]'>
                            <li className=''>
                                <Link to="/account/help" className="px-[10px] py-[20px] flex justify-between items-center">
                                    <span className='flex justify-start items-center'>
                                        <img src="/assets/images/help-support-icon.svg" alt="help icon" />
                                         <span className='text-[#252525] text-[16px] font-semibold ml-[18px] hover:text-opacity-75'>Help & Support</span>
                                    </span> 
                                    <img src='/assets/images/right-arrow-black.svg'></img>
                                </Link>
                            </li>
                            {/* <li className='border-1 border-b-[#E5E5E5]'>
                                <Link to="/account/feedback" className="px-[10px] py-[20px] flex justify-between items-center">
                                    <span className='flex justify-start items-center'>
                                        <img src="/assets/images/ic_outline-feedback.svg" alt="feedback icon" />
                                         <span className='text-[#252525] text-[16px] font-semibold ml-[18px] hover:text-opacity-75'>Feedback Form</span>
                                    </span> 
                                    <img src='/assets/images/right-arrow-black.svg'></img>
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </div>
                {/* <div className="creater-wrapper px-3 lg:px-0 lg:mx-[30px] w-full lg:w-[92%] mt-[20px] mb-[40px] sm:mb-[80px]">
                     <div className="creator-ct flex justify-between items-center my-[30px] px-[10px] sm:px-[24px] py-[18px] sm:py-[25px] bg-[#E6E6E6] rounded-[3px]">
                        <div className="content-ct flex justify-between items-center">
                            <img src="/assets/images/creator_img.svg" className="w-[26px] lg:w-[42px]" alt="creator img" />
                            <h2 className='text-[24px] text-[#252525] font-bold mx-[10px] hidden lg:block'>Are you a creator?</h2>
                            <h2 className='text-[16px] text-[#252525] font-bold mx-[10px] block lg:hidden'>Create unlimited bots</h2>
                            <p className='text-[#252525] m-0 mt-[8px] font-semibold text-[15px] hidden lg:block'>Join our creative program to create infinite bots</p>
                        </div>
                        <div className="button-ct">
                            <button className='text-[#fff] bg-[#2C2D98] h-[35px] flex justify-center items-center hover:bg-opacity-75 w-[150px] text-[16px] px-[0px] rounded-[6px] hidden sm:flex'>Become creator</button>
                            <button className='text-[#fff] bg-[#2C2D98] h-[35px] flex justify-center items-center hover:bg-opacity-75 w-[100px] text-[16px] px-[0px] rounded-[6px] sm:hidden'>Join Studio</button>
                        </div>
                    </div>
                </div> */}
                <div className="bottom-sec px-3 lg:px-0 lg:mx-[30px] w-full lg:w-[92%] mt-[50px]">
                    {/* <a href="" className='text-gray-600 font-semibold w-full text-[13px] block text-center hover:text-gray-500'>Terms of service & Privacy policy</a> */}
                    <a href="/logout" className='w-full text-[#C91414] text-[18px] h-[45px] flex justify-center items-center rounded-[6px] w-full font-bold my-[20px] bg-[#fff] outline outline-offset-2 outline-1 outline-[#C91414]'>Logout</a>
                    <a  className='text-[#252525] font-semibold w-full text-[16px] block text-center hover:text-gray-600 underline mb-[30px] cursor-pointer' onClick={deleteUserdata}>Delete Account</a>
                </div>
            </div>
        </div>: !loading ? <p className='m-[15px] sm:m-[25px] text-[#5a5a61] font-semibold'>You are not logged In! Please <Link to="/login" className='text-[#2C2D98] font-semibold underline'>Login</Link> and try again</p>:null}
    </div>
  )
}

export default Account