import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { Link } from "react-router-dom";
import Highlight from './Highlight';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Channelcard = ({ data, createChannel, imagePath, highlight }) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const location = useLocation();

  // function openBotDetails(){
  //   navigate(`/botprofile/${data?.attributes?.Name}`);
  // }

  const botData = data?.attributes ? data?.attributes : data;
  const profileImg = data?.attributes?.ProfileImage?.data?.attributes ? data?.attributes?.ProfileImage?.data?.attributes : data?.ProfileImage;
  const botTags = botData?.tags?.data ? botData?.tags?.data : botData?.tags 

  function openBotDetails(name) {
    navigate(`/botprofile/${name}`);
  }

  const createNewChannel = () => {
    var dataObject = { name: botData.Name, bot_id: [botData.bot_id], image_url: profileImg?.url ? imagePath + profileImg?.url : 'https://static.sendbird.com/sample/cover/cover_14.jpg' };
    localStorage.setItem('dataKey', JSON.stringify(dataObject));
    navigate("/login");
  }

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 1)+'...';
    } else {
      return text;
    }
  }
  
  const [ppState,setPpstate] = useState(false);
  const [deviceType, setDeviceType] = useState('');
  const [browserType, setBrowserType] = useState('');
  
  useEffect(()=>{
      if((("standalone" in window.navigator) && !window.navigator.standalone) || ( !window.matchMedia('(display-mode: standalone)').matches)){
          setPpstate(true)
      }
      const userAgent = navigator.userAgent;
      if (/Android/i.test(userAgent)) {
          setDeviceType('Android');
      } 
      else if (/iPhone/i.test(userAgent)) {
          setDeviceType('IOS');
          const userAgent = navigator.userAgent.toLowerCase();
          if (userAgent.indexOf('chrome') > -1) {
              setBrowserType('ios-chrome');
          }  else if (userAgent.indexOf('safari') > -1) {
              setBrowserType('ios-safari');
          } 
      } else if (/Windows Phone/i.test(userAgent)) {
          setDeviceType('Windows Phone');
      } else {
          setDeviceType('Other');
      }
  },[])

  return (
    <div className={location.pathname.split('/').slice(1) == 'search' && user ? 'w-[48%] channel-card flex items-start px-4 pt-3 pb-4 border rounded-md h-auto mx-0 sm:mx-1 sm:mr-2 my-2 md:my-4 flex-col relative' : location.pathname.split('/').slice(1) == 'search' && !user ? 'w-[49%] channel-card flex items-start px-4 pt-3 pb-4 border rounded-md h-auto mx-0 sm:mx-1 sm:mr-2 my-2 md:my-4 flex-col relative' : user ? 'w-[46.5%] channel-card flex items-start px-4 pt-3 pb-4 border rounded-md h-auto mx-0 sm:mx-1 sm:mr-2 my-2 md:my-4 flex-col relative': 'w-[31.5%] channel-card flex items-start px-4 pt-3 pb-4 border rounded-md h-auto mx-0 sm:ml-1 sm:mr-3 my-2 md:my-4 flex-col relative'}>
      <div className="img-ct top-sec flex flex-row items-center w-full">
        <div className="img-innet-ct">
          <img className=" mb-3 rounded-full shadow-lg" src={profileImg != null ? imagePath + profileImg.url : "/assets/images/app_dark_img.png"} alt="Bonnie image" />
        </div>
        <h5 className="mb-1 text-[#2C2D98] ml-3 text-base md:text-lg font-bold text-left ">
          {/* <Highlight text={botData.Name} highlight={highlight} /> */}
          {botData.Name}
        </h5>
        <div className="ml-5 sm:ml-4 sm:right-2 sm:top-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full border tag">
          Free
        </div>
        <a className='card-arrow flex-1 cursor-pointer' onClick={() => openBotDetails(botData?.Slug)}>
          <img className="float-right" src="/assets/images/card-gray-right-arrow.svg" alt="card-arrow-icon" />
        </a>

      </div>

      <div className="content-ct mt-4 h-full flex flex-col justify-between">
        <p className='description text-left font-semibold text-sm text-gray-400'>
          {/* <Highlight text={botData.Description} highlight={highlight} /> */}
          {botData && (deviceType == "IOS" || deviceType == "Android" || deviceType == "Windows Phone") ? truncateText(botData?.Description, 150) : truncateText(botData?.Description, 230) }
        </p>
        {botTags?.length > 0 ?
          <div className='flex overflow-auto scroll-hidden text-gray-400'>
            {botTags?.map((data, key) =>
              <div className="mr-2 my-2 tagTitle inline-flex items-center font-bold leading-[16px] uppercase px-3 py-[3px] rounded-full bg-white text-gray-400 border" key={key}>
                {/* <Highlight text={data?.attributes ? data?.attributes?.Name : data?.Name} highlight={highlight} /> */}
                {data?.attributes ? data?.attributes?.Name : data?.Name}
              </div>
            )
            }
          </div> :
          null
        }
        <div className="flex mt-4 space-x-3 md:mt-4 w-full ">
          {
            user ?
              <a className="start-convr-link w-full inline-flex cursor-pointer items-center justify-center px-4 py-3 text-xs font-medium text-center text-white bg-[#2C2D98] rounded-lg hover:bg-blue-800  dark:focus:ring-blue-800" onClick={() => createChannel(botData.Name, botData.bot_id, "pdf", "chanel1", profileImg?.url ? imagePath + profileImg?.url : 'https://static.sendbird.com/sample/cover/cover_14.jpg')}>
                Chat Now
              </a>
              :
              <a className="start-convr-link w-full inline-flex cursor-pointer items-center justify-center px-4 py-3 text-xs font-medium text-center text-white bg-[#2C2D98] rounded-lg hover:bg-blue-800  dark:focus:ring-blue-800" onClick={() => createNewChannel()}>
                Sign Up
              </a>
          }
        </div>
      </div>
    </div>
  )
}

export default Channelcard