import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryCard from './CategoryCard';
import ProfileIcon from './ProfileIcon';
import { SENDBIRD_INFO } from '../constants/constants';
import Featuredcard from './FeaturedCard';
import Slider from "react-slick";
import RecomendedCard from './RecomendedCard';
import ExploreBotCard from './ExploreBotCard';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import Notiflix from 'notiflix';
import { useOutletContext } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import Mobfooter from './Mobfooter';
import { useQuery } from '@tanstack/react-query';

const ExplorePage = ({createChannel,handleLoadMemberSelectionList,HandleHomeBtn, HandleLibBtn, handleSearchBtn}) => {
    const [existingOpt, setExistingOpt] = useState([]);
    const [active, setActive] = useState(0);
    const [user, loading, error] = useAuthState(auth);
    //   const [createChannel,userNameInputValue] = useOutletContext();
    const navigate = useNavigate();

    // const [data, setdata]  = useState();  

    // useEffect(()=>{
    //       getdata();
    // },[]);

    // function getdata(){
    //     Notiflix.Loading.standard();
    //     axios({
    //         url:`${SENDBIRD_INFO.newBotUrl}/homepage`,
    //         method: 'get',
    //         headers: {
    //           'Api-token': SENDBIRD_INFO.apiToken
    //         }
    //       })
    //       .then((res) => res)
    //       .then((response) => {
    //           setdata(response);
    //         // console.log("check home = ",response);
    //         Notiflix.Loading.remove();
    //       })
    //       .catch((err) => {
    //           console.log(err.message+"file-error");
    //       }); 
    // }

    const getdata = async () => {
        // Notiflix.Loading.standard();
        try {
          const response = await axios.get(`${SENDBIRD_INFO.newBotUrl}/homepage`, {
            headers: {
              'Api-token': SENDBIRD_INFO.apiToken
            }
          });
    
          const data = response;
        //   Notiflix.Loading.remove();
          return data;
        } catch (error) {
          console.log(error.message + 'file-error');
          throw error;
        }
      }
    
      const { data, isLoading } = useQuery({
        queryKey: [],
        queryFn: getdata
      })
    
    const closeChannel = () => {
        document.querySelector('.channel').style.zIndex = '0';
    }

    const observer = new MutationObserver(function(mutations, observer) {
        if(window.innerWidth > 767){
            let isDown = false;
            let startX;
            let scrollLeft;
            const slider = document.getElementById('feature_slider');

            const end = () => {
                isDown = false;
                slider.classList.remove('active');
            }

            const start = (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;	
            }

            const move = (e) => {
                if(!isDown) return;

            e.preventDefault();
            const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            const dist = (x - startX);
            slider.scrollLeft = scrollLeft - dist;
            }

            if(slider){
            (() => {
                slider.addEventListener('mousedown', start);
                slider.addEventListener('touchstart', start);

                slider.addEventListener('mousemove', move);
                slider.addEventListener('touchmove', move);

                slider.addEventListener('mouseleave', end);
                slider.addEventListener('mouseup', end);
                slider.addEventListener('touchend', end);
            })();
        }

        }

        if(window.innerWidth > 767){
            let isDown = false;
            let startX;
            let scrollLeft;
            const slider = document.getElementById('recomended_bot_slider');

            const end = () => {
                isDown = false;
                slider.classList.remove('active');
            }

            const start = (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;	
            }

            const move = (e) => {
                if(!isDown) return;

            e.preventDefault();
            const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            const dist = (x - startX);
            slider.scrollLeft = scrollLeft - dist;
            }

            if(slider){
            (() => {
                slider.addEventListener('mousedown', start);
                slider.addEventListener('touchstart', start);

                slider.addEventListener('mousemove', move);
                slider.addEventListener('touchmove', move);

                slider.addEventListener('mouseleave', end);
                slider.addEventListener('mouseup', end);
                slider.addEventListener('touchend', end);
            })();
        }

        }
    
        if(window.innerWidth > 767){
            let isDown = false;
            let startX;
            let scrollLeft;
            const slider = document.getElementById('explore_bot_slider');

            const end = () => {
                isDown = false;
                slider.classList.remove('active');
            }

            const start = (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;	
            }

            const move = (e) => {
                if(!isDown) return;

            e.preventDefault();
            const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            const dist = (x - startX);
            slider.scrollLeft = scrollLeft - dist;
            }

            if(slider){
                (() => {
                    slider.addEventListener('mousedown', start);
                    slider.addEventListener('touchstart', start);

                    slider.addEventListener('mousemove', move);
                    slider.addEventListener('touchmove', move);

                    slider.addEventListener('mouseleave', end);
                    slider.addEventListener('mouseup', end);
                    slider.addEventListener('touchend', end);
                })();
            }
        }
    });
    observer.observe(document, { childList: true, subtree: true });
  return (
    <div className='lg:mx-0 bot-lib explore-page w-full border border-2 overflow-y-auto overflow-x-hidden bg-[#F5F5F5] sm:bg-[#fff] pb-[100px] sm:pb-[0px] relative min-h-full'>
        {data?.data?.data?.attributes?
        <div className="wrapper sm:mx-0 mt-3 pt-2 w-[100%]">
            <h1 className='text-2xl font-bold px-0 sm:px-2  pb-2 text-[#252525] flex items-center mx-3 lg:mx-4 '>
            Hi{user && " "+user.displayName}, Welcome to Brainstormer!</h1>
            {data?.data?.data?.attributes?.featured_bots?.data?.length != 0?
            <div className='bot-featured-wrapper'>
                <h2 className='mx-3 lg:mx-4 text-[20px] font-bold px-0 sm:px-2  pt-4 pb-0 text-[#2C2D98]'>Featured Bots</h2>
                <div id="feature_slider" className="feature-slider px-3 sm:px-0 sm:mx-3 overflow-x-auto lg:mx-4 featured-content-ct flex rounded-lg justify-start mt-4 sm:mt-2 pb-6 w-[98%] pr-4 sm:pr-[10px] lg:pr-[30px] relative">
                    {/* {data && data?.data?.data?.attributes?.featured_creators?.data.map((item, indx)=>
                        <Featuredcard key={indx} featuredBot={item} creator="creator"/>
                    )}   */}
                    {data ? data?.data?.data?.attributes?.featured_bots?.data.map((item, indx)=>
                        <Featuredcard key={indx} featuredBot={item} creator="bots"/>
                    ):null}  
                </div>
            </div>:null}
            {data?.data?.data?.attributes?.featured_categories?.data.length != 0?
            <div className="bot-category-wrapper sm:mr-[10px] lg:mr-[30px] xl:mr-[0px]">
                <h2 className='mx-3 lg:mx-4 text-[20px] font-bold px-0 sm:px-2  pt-4 pb-0 text-[#2C2D98]'>Featured Categories</h2>
                <div className="mx-3 lg:mx-4 bot-content-ct flex flex-wrap w-full rounded-lg justify-between sm:justify-start mt-4 sm:mt-2 pb-[30px]">
                    {data ? data?.data?.data?.attributes?.featured_categories.data.map((item,indx) =>
                        <CategoryCard data={item} key={indx} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />
                    ):null
                    }
                </div>
            </div>:null}
            <div className="create-storm-wrapper bg-[#EBEBFF]  p-4 lg:px-5 relative">
                <img src="/assets/images/storm-icon.svg" alt="thunder" className='absolute left-[8px] top-[50%] translate-y-[-50%] lg:translate-y-[-50%] z-[0]' loading="lazy"/>
                <h3 className='text-[16px] text-[#252525] font-bold mb-[10px] block sm:hidden z-[2] relative'>Create Your First Storm & Get Brainstorming</h3>
                <div className='flex justify-between items-center sm:ml-[5px] lg:ml-[2px] z-[2] relative'>
                    <div className="content-ct">
                        <h3 className='text-[16px] text-[#252525] font-bold mb-[10px] hidden sm:block'>Create Your First Storm & Get Brainstorming</h3>
                        <p className='text-[#252525] text-[14px] mb-0 font-bold'>What’s a storm? </p>
                        <p className='text-[#252525] text-[14px]'>Your personalised AI bots that helps you with you day to day activities.</p>
                    </div>
                    <div className="button-ct">
                        <button className='bg-[#2C2D98] rounded-[10px] h-[40px] flex' onClick={() => user ? (handleLoadMemberSelectionList()) : (navigate("/login"))}>Create Storm</button>
                    </div>
                </div>
            </div>
            {/* {data?.data?.data?.attributes?.recommended_bots?.data.length != 0 ?
            <div className="recomended-bots-ct pt-4">
                <h2 className='mx-3 lg:mx-4 text-[20px] font-bold px-0 sm:px-2  pt-4 pb-0 text-[#2C2D98]'>Recommended Bots</h2>
                <div id="recomended_bot_slider" className="recomended-bot-slider mx-3 lg:mx-4 recomended-bot-content-ct bot-content-ct flex flex-nowrap w-full rounded-lg justify-start mt-4 sm:mt-2 pb-[10px] w-[98%] pr-4 relative">
                    {data ? data?.data?.data?.attributes?.recommended_bots.data.map((item,indx) =>
                        <RecomendedCard data={item} key={indx} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />
                    ):
                    <div className="loader-ct">
                        <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader'></img>
                    </div>
                    }
                </div>
            </div>:null
            } */}
            {data?.data?.data?.attributes?.Questions.length?
            <div className="explore-bot-wrapper mt-5 mb-[30px]">
                <h2 className='mx-3 lg:mx-4 text-[20px] font-bold px-0 sm:px-2  pt-0 pb-0 text-[#2C2D98]'>Explore Bots</h2>
                <div id="explore_bot_slider" className="explore-bot-slider px-3 sm:px-0 sm:pr-[30px] sm:mx-3 lg:mx-4 explore-bot-content-ct bot-content-ct flex flex-nowrap w-full rounded-lg justify-start mt-4 sm:mt-2 pb-[10px] w-[98%] pr-4 sm:pr-[20px] lg:pr-[30px] relative">
                    {data ? data?.data?.data?.attributes?.Questions.map((item,indx) =>
                        <ExploreBotCard  data={item} key={indx} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />
                    ):null
                    }
                </div>
            </div>:null}
            {/* <div className="create-storm-wrapper bg-[#E6E6E6] mt-[30px] p-4 lg:px-5 lg:py-4 mx-[15px] mb-[40px] sm:mx-[0px] sm:mb-[0px]">
                <h3 className='text-[18px] text-[#252525] font-bold mb-[0px] block sm:hidden'>Are you a creator? </h3>
                <div className='flex justify-between items-center'>
                    <div className="content-ct">
                        <h3 className='text-[18px] text-[#252525] font-bold mb-[0px] hidden sm:block'>Are you a creator? </h3>
                        <p className='text-[#252525] text-[14px] mb-0'>Join our creative program to create infinite bots.</p>
                    </div>
                    <div className="button-ct">
                        <button className='bg-[#2C2D98] rounded-[10px] h-[40px] flex'>Become creator</button>
                    </div>
                </div>
            </div> */}
        </div>:
        <div className="loader-ct h-[100%] absolute top-[-25px] left-0">
            <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader' loading="lazy"></img>   
        </div>}
         {/* <Mobfooter handleLoadMemberSelectionList={handleLoadMemberSelectionList} HandleHomeBtn={HandleHomeBtn} HandleLibBtn={HandleLibBtn}/> */}
    </div>
  )
}

export default ExplorePage