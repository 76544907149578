import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { SENDBIRD_INFO } from '../constants/constants';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { Link, useOutletContext, useParams } from 'react-router-dom';
import Channelcard from './Channelcard';
import Notiflix from 'notiflix';


const CreatorProfile = () => {
    const [user, loading, error] = useAuthState(auth);
    const [createChannel, userNameInputValue] = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);

    let params = useParams();
    const slug = params.slug

    const [existingOpt, setExistingOpt] = useState([]);

    const [creatorData, setCreatorData] = useState();

    const getCreatorDetails = async (slug) => {
        setIsLoading(true);

        const headers = {
            'Api-token': SENDBIRD_INFO.apiToken,
        };

        axios({
            url: `${SENDBIRD_INFO.newBotUrl}/creator/${slug}`,
            method: 'get',
            headers: headers
        })
            .then((res) => res)
            .then((response) => {
                setCreatorData(response);
                setExistingOpt(response?.data[0]?.bots);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err.message + "file-error");
            });
    }

    useEffect(() => {
        getCreatorDetails(slug);
    }, [params, user]);

    const closeChannel = () => {
        document.querySelector('.channel').style.zIndex = '0';
    }

    if (window.innerWidth > 767) {
        let isDown = false;
        let startX;
        let scrollLeft;
        const slider = document.getElementById('explore_bot_slider');

        const end = () => {
            isDown = false;
            slider.classList.remove('active');
        }

        const start = (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        }

        const move = (e) => {
            if (!isDown) return;

            e.preventDefault();
            const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            const dist = (x - startX);
            slider.scrollLeft = scrollLeft - dist;
        }

        if (slider) {
            (() => {
                slider.addEventListener('mousedown', start);
                slider.addEventListener('touchstart', start);

                slider.addEventListener('mousemove', move);
                slider.addEventListener('touchmove', move);

                slider.addEventListener('mouseleave', end);
                slider.addEventListener('mouseup', end);
                slider.addEventListener('touchend', end);
            })();
        }
    }
    if (window.innerWidth > 767) {
        let isDown = false;
        let startX;
        let scrollLeft;
        const slider = document.getElementById('related_bot_slider');

        const end = () => {
            isDown = false;
            slider.classList.remove('active');
        }

        const start = (e) => {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        }

        const move = (e) => {
            if (!isDown) return;

            e.preventDefault();
            const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
            const dist = (x - startX);
            slider.scrollLeft = scrollLeft - dist;
        }

        if (slider) {
            (() => {
                slider.addEventListener('mousedown', start);
                slider.addEventListener('touchstart', start);

                slider.addEventListener('mousemove', move);
                slider.addEventListener('touchmove', move);

                slider.addEventListener('mouseleave', end);
                slider.addEventListener('mouseup', end);
                slider.addEventListener('touchend', end);
            })();
        }
    }

    if(isLoading){
        return(
            <div className="loader-ct">
                <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader'></img>
            </div>
        )
    }

    return (
        <div className='creator-profile-wrapper w-full'>
            <div className='creator-profile-inner w-full'>
                <div className="profile-header bg-[#F5F5F5] px-3 sm:px-6 w-full py-3 sm:py-5 relative h-[110px] sm:h-[160px] ">
                    <div className="breadcrumbs-ct hidden sm:block">
                        <ul className='flex items-center'>
                            <li className='text-[14px] list-none text-[#5A5A61] mr-[7px] font-semibold cursor-pointer'><Link to={'/'}>Home</Link></li>/
                            {/* <li className='text-[14px] list-none text-[#5A5A61] mx-[7px] font-semibold cursor-pointer'><a>Category Name</a></li>/ */}
                            <li className='text-[14px] list-none text-[#252525] mx-[7px] font-semibold'>{creatorData?.data[0]?.Name}</li>
                        </ul>
                    </div>

                    <div className='back-btn sm:hidden absolute top-[28px]'>
                        <Link to={`/category`} className='back-link'>
                            <img src="/assets/images/arrow_back_2.svg" alt="back-arrow" />
                        </Link>
                    </div>

                    <div className='bot-name-wrapper flex justify-start items-start mt-[10px] sm:mt-[30px] sm:absolute sm:bottom-[-35px] pl-[40px] sm:pl-[0px]'>
                        <div className="img-ct w-[65px] h-[65px] sm:w-[120px] sm:h-[120px] overflow-hidden rounded-[50%]">
                            <img className="rounded-full" src={creatorData?.data[0]?.ProfileImage?.url ? "https://studio.brainstormer.io" + creatorData?.data[0]?.ProfileImage?.url : "/assets/images/bot-img2.svg"} alt="creator-img" />
                        </div>
                        <div className='bot-name-ct sm:ml-4 ml-3 sm:mt-[15px] flex justify-start items-start'>
                            <div className='mr-2 sm:mr-3'>
                                <h1 className='text-[#252525] text-[22px] sm:text-[24px] font-bold leading-[24px] sm:leading-[36px]'>{creatorData?.data[0]?.Name}</h1>
                                <span className='text-[14px] sm:text-[16px] text-[#252525]'>{creatorData?.data[0]?.email}</span>
                                {/* <span className='rating-tag bg-[#F8B93F] text-[#fff] px-[4px] py-[1px] rounded-[3px] text-[12px] flex w-fit mt-2'>4.5 <img src="/assets/images/start-white.svg" alt="rating star" /></span> */}
                            </div>
                            {/* <div className='ml-2 w-[75px] sm:w-[30px] h-[30px] relative mt-[4px] sm:mt-[10px]'> */}
                                {/* <span className="price-tag bg-[#ddedda] text-[#74C265] px-[8px] rounded-[10px] text-[14px] inline-block">
                                Free
                            </span> */}
                           
                            {/* </div> */}
                        </div>
                        <a className='cursor-pointer shareIcon'>
                            <img src="/assets/images/ic_baseline-share.svg" alt="share-icon" />
                        </a>
                    </div>
                </div>

                <div className="bot-details-wrapper mt-[0px] sm:mt-[55px] px-5 sm:px-6  w-full">
                    <p className='bot-details-para text-[16px] text-[#5A5A61] leading-[20px] sm:ml-1 sm:mr-[30px]'>{creatorData?.data[0]?.Description}</p>
                </div>

                <div className="bot-category-wrapper px-5 sm:pl-6 sm:pr-0">

                    {existingOpt.length !== 0 ? 
                    <>
                        <h2 className='text-[20px] font-bold pt-2 sm:pt-4 pb-0 text-[#252525] sm:ml-1'>Bots Created</h2>
                        <div className="bot-content-ct flex flex-wrap w-full rounded-lg justify-between sm:justify-start mt-4 sm:mt-2 pb-[30px]">
                            {existingOpt.map((item, i) =>
                                <Channelcard data={item} key={i} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />
                            )}
                        </div>
                     </> : 
                     null
                    }
                </div>
            </div>
        </div>
    )
}

export default CreatorProfile