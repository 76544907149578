import React from 'react'
import { json, useNavigate,BrowserRouter as Router,} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";

const ExploreBotCard = ({data, createChannel,imagePath}) => {

const [user, loading, error] = useAuthState(auth);
const navigate = useNavigate();

const createNewChannel = () =>{
    var dataObject ={name:data?.bot?.data?.attributes?.Name, bot_id:[data?.bot?.data?.attributes?.bot_id], image_url: data?.bot?.data?.attributes?.ProfileImage?.data?.attributes?.url ? imagePath+data?.bot?.data?.attributes?.ProfileImage?.data?.attributes?.url : 'https://static.sendbird.com/sample/cover/cover_14.jpg',  defaultQue:data?.Question };
    localStorage.setItem('dataKey', JSON.stringify(dataObject));
    navigate("/login");
}

  
  return (
    <div className="bg-[#fff] explore-bot-card channel-card flex items-start justify-between border rounded-md w-auto h-auto mx-0 sm:mx-2 my-2 md:my-4 flex-col relative">
        <div className="img-ct top-sec flex flex-row items-center p-3">
            <h3 className="text-[#252525] md:text-[16px] font-bold text-left">{data && data?.Question}</h3>
        </div>
      
        <div className="bottom-ct mt-3 w-full flex flex-col justify-between bg-[#E0E0E0] h-[41px]">
            {user ?
            <a className='flex justify-between items-center h-[100%] cursor-pointer p-3' onClick={()=>createChannel(data?.bot?.data?.attributes?.Name, data?.bot?.data?.attributes?.bot_id, "pdf", "chanel1", data?.bot?.data?.attributes?.ProfileImage.data?.attributes?.url ? imagePath+data?.bot?.data?.attributes?.ProfileImage.data?.attributes?.url : 'https://static.sendbird.com/sample/cover/cover_14.jpg', data?.Question)}>
                <div className='flex justify-center items-center'>
                    <img src={data?.bot?.data?.attributes?.ProfileImage.data?.attributes?.url ? imagePath+data?.bot?.data?.attributes?.ProfileImage.data?.attributes?.url:'/assets/images/app_dark_img.png'} alt="heart-icon" className=' w-[22px] h-[22px] rounded-[50%]' loading="lazy" />
                    <span className='text-[#3A3BA7] font-semibold ml-2'>{data && data?.bot?.data?.attributes?.Name}</span>
                </div>
                <img src="/assets/images/right-arrow-blue.svg" alt="right arrow" loading="lazy"/>
            </a>:
            <a className='flex justify-between items-center h-[100%] cursor-pointer p-3' onClick={()=>createNewChannel()}>
                <div className='flex justify-center items-center'>
                    <img src={data?.bot?.data?.attributes?.ProfileImage.data?.attributes?.url ? imagePath+data?.bot?.data?.attributes?.ProfileImage.data?.attributes?.url:'/assets/images/app_dark_img.png'} alt="heart-icon" className=' w-[22px] h-[22px] rounded-[50%] p-[3px]' loading="lazy" />
                    <span className='text-[#3A3BA7] font-semibold ml-2'>{data && data?.bot?.data?.attributes?.Name}</span>
                </div>
                <img src="/assets/images/right-arrow-blue.svg" alt="right arrow" loading="lazy" />
            </a>
            }
            
        </div>
    </div>
  )
}

export default ExploreBotCard