import React, { useState, useEffect } from "react";
import Mobfooter from './Mobfooter';
import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet } from "react-helmet";
import axios from 'axios';
import { SENDBIRD_INFO } from '../constants/constants';
import { auth } from "../firebase";
import CreatorCard from "./CreatorCard";
import Channelcard from "./Channelcard";
import { Link } from "react-router-dom";
import Notiflix from 'notiflix';

const Search = ({ handleLoadMemberSelectionList, HandleHomeBtn, HandleLibBtn, createChannel, handleSearchBtn }) => {

    const [user] = useAuthState(auth);

    const tabs = [
        { id: 'bot', label: 'Bot' },
        { id: 'creator', label: 'Creator' },
    ];

    const [activeTab, setActiveTab] = useState(tabs[0].id);
    const [searchedKeyword, setSearchedKeyword] = useState('');
    const [keyword, setKeyword] = useState('');

    const [botList, setBotList] = useState([]);
    const [creatorList, setCreatorList] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const usertoken = await user?.getIdToken();
            const response = await axios.get(`${SENDBIRD_INFO.newBotUrl}/search?query=${keyword}`, {
                headers: {
                    Authorization: `Bearer ${usertoken}`,
                    'Api-token': SENDBIRD_INFO.apiToken,
                },
            });

            setBotList(response?.data?.bots);
            setCreatorList(response?.data?.users);
            setSearchedKeyword(keyword);
        } catch (error) {
            console.log(error.message + 'file-error');
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && event.target.value?.trim()?.length) {
            handleSearch();
        }
    };

    const handleKeyUp = (event) => {
        if (event.key === 'Backspace') {
            if (event.target.value.length === 0 && searchedKeyword) {
                getFeaturedBot()
            }
        }
    }

    const handleClearBtn = () => {
        setKeyword('');
        setSearchedKeyword('');
        getFeaturedBot();
    }

    const getFeaturedBot = async () => {
        setSearchedKeyword('');
        setLoading(true);
        // Notiflix.Loading.standard();

        try {
            const response = await axios.get(`${SENDBIRD_INFO.newBotUrl}/homepage`, {
                headers: {
                    'Api-token': SENDBIRD_INFO.apiToken,
                },
            });

            const botData = response?.data?.data?.attributes?.featured_bots?.data;
            const creatorData = response?.data?.data?.attributes?.featured_creators?.data;

            setBotList(botData);
            setCreatorList(creatorData);

        } catch (error) {
            console.log(error.message + 'file-error');
            throw error;
        } finally {
            setLoading(false);
            // Notiflix.Loading.remove();
        }
    }

    useEffect(() => {
        getFeaturedBot();
    }, [])

    let isBotTabEmpty = botList?.length === 0;
    let isCreatorTabEmpty = creatorList?.length === 0;

    useEffect(() => {
        if(isBotTabEmpty){
            setActiveTab('creator')
        } else {
            setActiveTab('bot')
        }
    }, [botList])

    return (
        <div className='w-full md:pl-3 lg:px-5 bot-lib '>
            <Helmet>
                <title>Brainstormer Search Bots</title>
            </Helmet>

            <div className="wrapper mx-4 sm:mx-2 mt-2 pt-2 relative">
                <div className="flex flex-col min-h-[84vh] bot-content-ct w-full bg-white rounded-lg justify-start mt-2 pb-[130px] sm:pb-[40px]">
                    <div className="w-full mt-2 flex items-center justify-start relative">
                        <input
                            type="search"
                            placeholder="Search Anything"
                            className={user ? "w-[85%] lg:w-[76%] min-h-[45px] border-[1px] border-[#e7e7e7] bg-[#f8fafc] rounded-[8px] p-3 text-[14px] text-[#637381] font-medium search-icon pr-[50px]":"w-[85%] sm:w-[100%] mr-2 min-h-[45px] border-[1px] border-[#e7e7e7] bg-[#f8fafc] rounded-[8px] p-3 text-[14px] text-[#637381] font-medium search-icon pr-[50px]" }
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            onKeyDown={handleKeyPress}
                            onKeyUp={handleKeyUp}
                        />
                        <div className={user ? "absolute right-[20%] lg:right-[26%] cursor-pointer":"absolute right-[20%] sm:right-[30px] cursor-pointer"}>
                            {
                                !keyword ?
                                    <img src="/assets/images/search-icon.svg" width={13} height={13} alt="search icon" /> :
                                    <img src="/assets/images/cancel-icon.svg" width={13} height={13} alt="cancel icon" onClick={handleClearBtn} />
                            }
                        </div>
                        {user ? 
                        <Link to="/account/help">
                            <div className="flex flex-1 items-center justify-start pl-6 sm:pl-8">
                                <img src="/assets/images/help-support.svg" width={24} height={24} alt="Help and Support Logo" />
                                <p className="text-[14px] font-bold pl-2 text-[#32209c] mb-0 hidden lg:block">Help & Support</p>
                            </div>
                        </Link> : null}
                    </div>
                    {loading ?
                        <div className="loader-ct mt-[0px] fixed sm:absolute left-0 top-0 z-50 flex-1">
                            <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader' className="mt-[-30px]"></img>
                        </div> : null 
                    }
                        <>
                            {/* {!searchedKeyword && (
                                <div className="flex items-center justify-start mt-4 w-full overflow-hidden scrollbar-hidden">
                                    <div className="trend-search flex items-center justify-center px-4 sm:px-2 py-1 border-[1px] border-[#eaeaea] rounded-[4px] mr-3">
                                        <img src="/assets/images/trend.svg" width={20} height={20} alt="trend-icon" />
                                        <p className="text-[14px] text-[#5a5a61] mb-0 ml-1">Business</p>
                                    </div>
                                    <div className="trend-search flex items-center justify-center px-4 sm:px-2 py-1 border-[1px] border-[#eaeaea] rounded-[4px] mr-3">
                                        <img src="/assets/images/trend.svg" width={20} height={20} alt="trend-icon" />
                                        <p className="text-[14px] text-[#5a5a61] mb-0 ml-1">Support</p>
                                    </div>
                                    <div className="trend-search flex items-center justify-center px-4 sm:px-2 py-1 border-[1px] border-[#eaeaea] rounded-[4px] mr-3">
                                        <img src="/assets/images/trend.svg" width={20} height={20} alt="trend-icon" />
                                        <p className="text-[14px] text-[#5a5a61] mb-0 ml-1">Empathy</p>
                                    </div>
                                    <div className="trend-search flex items-center justify-center px-4 sm:px-2 py-1 border-[1px] border-[#eaeaea] rounded-[4px] mr-3">
                                        <img src="/assets/images/trend.svg" width={20} height={20} alt="trend-icon" />
                                        <p className="text-[14px] text-[#5a5a61] mb-0 ml-1">Empathy</p>
                                    </div>
                                </div>
                            )} */}

                            {!(isBotTabEmpty && isCreatorTabEmpty) && <h3 className="text-[#2c2d98] text-[20px] font-bold mt-6 mb-3 break-words">{searchedKeyword ? `Search Results for "${searchedKeyword}"` : "Popular"}</h3>}
                            <div className={`flex ${!(isBotTabEmpty && isCreatorTabEmpty) ? 'border-b-[0.5px] border-[#e5e5e5]' : ''} w-full sm:w-[97.5%]`} role="tabList">
                                {tabs.map(tab => {

                                    if(isBotTabEmpty && isCreatorTabEmpty){
                                        return null;
                                    }

                                    return (
                                        <button
                                            key={tab.id}
                                            className={`text-[14px] pb-1 bg-transparent text-black p-0 mr-8 rounded-none ${activeTab === tab.id ? 'font-bold selected-tab' : 'font-medium'}`}
                                            role="tab"
                                            aria-selected={activeTab === tab.id}
                                            aria-controls={`panel-${tab.id}`}
                                            onClick={() => setActiveTab(tab.id)}
                                            hidden={(tab.id === 'bot' && isBotTabEmpty) || (tab.id === 'creator' && isCreatorTabEmpty)}
                                        >
                                            {tab.label}
                                        </button>
                                    )

                                })}
                            </div>
                            <div className="bg-transparent rounded-b-lg">
                                {tabs.map(tab => {

                                    if (isBotTabEmpty && isCreatorTabEmpty) {
                                        return null; 
                                    }

                                    return(    
                                        <div
                                            key={tab.id}
                                            id={`panel-${tab.id}`}
                                            role="tabpanel"
                                            aria-labelledby={tab.id}
                                            hidden={activeTab !== tab.id}
                                        >
                                            <div className="bot-content-ct search-cards flex flex-wrap w-full bg-transparent rounded-lg justify-start mt-2 pb-7 sm:pb-14">
                                                {
                                                    activeTab === tab.id && tab.id === "bot" ? (
                                                        botList.length > 0 && (
                                                            botList.map((item, i) => (
                                                                <Channelcard
                                                                    data={item}
                                                                    key={i}
                                                                    createChannel={createChannel}
                                                                    imagePath={SENDBIRD_INFO.studioUrl}
                                                                    highlight={searchedKeyword}
                                                                />
                                                            ))
                                                        )
                                                    ) : (
                                                        creatorList.length > 0 && (
                                                            creatorList.map((item, i) => (
                                                                <CreatorCard
                                                                    data={item}
                                                                    key={i}
                                                                    highlight={searchedKeyword}
                                                                />
                                                            ))
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            {(isBotTabEmpty && isCreatorTabEmpty) && !loading ? <p className="py-[40px] px-1 text-[#2c2d98] font-semibold text-[20px]">No Results Found</p>:null}
                        </>
                </div>
            </div>
            {/* <Mobfooter handleLoadMemberSelectionList={handleLoadMemberSelectionList} HandleHomeBtn={HandleHomeBtn} HandleLibBtn={HandleLibBtn} handleSearchBtn={handleSearchBtn} /> */}
        </div>
    )
}

export default Search;