import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CategoryCard from './CategoryCard';
import ProfileIcon from './ProfileIcon';
import { SENDBIRD_INFO } from '../constants/constants';
import Featuredcard from './FeaturedCard';
import Slider from "react-slick";
import RecomendedCard from './RecomendedCard';
import ExploreBotCard from './ExploreBotCard';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";
import Botquestions from "./BotQuestions";
import { useNavigate } from 'react-router-dom';
import Notiflix from 'notiflix';
import Mobfooter from './Mobfooter';

const Botprofile = () => {
    const [user, authLoading, error] = useAuthState(auth);

    const [botData, setBotdata]  = useState(); 
    const slugObj = useParams();
    const [createChannel,userNameInputValue] = useOutletContext(); 

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(()=>{
    //   getAllBot();
      getBotDetails(slugObj.slug);
    },[slugObj]);

    const getBotDetails = async (slug) => {
        console.log("b called");
        // Notiflix.Loading.standard();
        setLoading(true);
        const headers = {
            'Api-token': SENDBIRD_INFO.apiToken,
        };
        if (user) {
            const usertoken = await user.getIdToken();
            headers.Authorization = `Bearer ${usertoken}`;
        }
        axios({
            url:`${SENDBIRD_INFO.newBotUrl}/bot/${slug}`,
            method: 'get',
            headers: headers
          })
          .then((res) => res)
          .then((response) => {
              setBotdata(response);
            //   Notiflix.Loading.remove();
              setLoading(false);
          })
          .catch((err) => {
              console.log(err.message+"file-error");
          }); 
    }
    
    const createNewChannel = () =>{
        var dataObject ={name:botData?.data?.data[0]?.attributes.Name, bot_id:[botData?.data?.data[0]?.attributes.bot_id], image_url: botData?.data?.data[0]?.attributes?.ProfileImage?.data?.attributes?.url ? SENDBIRD_INFO.studioUrl+botData?.data?.data[0]?.attributes?.ProfileImage?.data?.attributes?.url : 'https://static.sendbird.com/sample/cover/cover_14.jpg'};
        localStorage.setItem('dataKey', JSON.stringify(dataObject));
        navigate("/login");
    }  

    const closeChannel = () => {
        document.querySelector('.channel').style.zIndex = '0';
    }
 
    if(window.innerWidth > 767){
        let isDown = false;
        let startX;
        let scrollLeft;
        const slider = document.getElementById('explore_bot_slider');

        const end = () => {
            isDown = false;
            slider.classList.remove('active');
        }

        const start = (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;	
        }

        const move = (e) => {
            if(!isDown) return;

        e.preventDefault();
        const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
        const dist = (x - startX);
        slider.scrollLeft = scrollLeft - dist;
        }

        if(slider){
            (() => {
                slider.addEventListener('mousedown', start);
                slider.addEventListener('touchstart', start);

                slider.addEventListener('mousemove', move);
                slider.addEventListener('touchmove', move);

                slider.addEventListener('mouseleave', end);
                slider.addEventListener('mouseup', end);
                slider.addEventListener('touchend', end);
            })();
        }
    }
    if(window.innerWidth > 767){
        let isDown = false;
        let startX;
        let scrollLeft;
        const slider = document.getElementById('related_bot_slider');

        const end = () => {
            isDown = false;
            slider.classList.remove('active');
        }

        const start = (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;	
        }

        const move = (e) => {
            if(!isDown) return;

        e.preventDefault();
        const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
        const dist = (x - startX);
        slider.scrollLeft = scrollLeft - dist;
        }

        if(slider){
            (() => {
                slider.addEventListener('mousedown', start);
                slider.addEventListener('touchstart', start);

                slider.addEventListener('mousemove', move);
                slider.addEventListener('touchmove', move);

                slider.addEventListener('mouseleave', end);
                slider.addEventListener('mouseup', end);
                slider.addEventListener('touchend', end);
            })();
        }
    }
  return (
   <div className='bot-profile-wrapper w-full min-h-[95%] sm:h-full relative'>
    { botData?.data?.data[0]?.attributes ? 
        <div className='bot-profile-inner w-full flex flex-col justify-between h-full pb-[60px] sm:pb-[0px]'>
            <div className='sm:pb-[0px]'>
                <div className="profile-header bg-[#F5F5F5] px-3 sm:px-6 w-full py-5 relative min-h-[130px] sm:h-[160px]">
                    <div className="breadcrumbs-ct hidden sm:block">
                        <ul className='flex items-center'>
                            <li className='text-[14px] list-none text-[#5A5A61] mr-[7px] font-semibold cursor-pointer'><Link to={'/'}>Home</Link></li>/
                            <li className='text-[14px] list-none text-[#5A5A61] mx-[7px] font-semibold cursor-pointer'><Link to={'/library'}>Library</Link></li>/
                            <li className='text-[14px] list-none text-[#5A5A61] mx-[7px] font-semibold cursor-pointer'><Link to={`/library/category/${botData?.data?.data[0]?.attributes?.categories?.data[0]?.id}`}>{botData?.data?.data[0]?.attributes?.categories?.data[0].attributes?.Name}</Link></li>/
                            <li className='text-[14px] list-none text-[#252525] mx-[7px] font-semibold'>{botData?.data?.data[0]?.attributes?.Name}</li>
                        </ul>
                    </div>
                    <div className='back-btn sm:hidden absolute top-[30px]'>
                        <Link to={`/library/category/${botData?.data?.data[0]?.attributes?.categories?.data[0]?.id}`} className='back-link'>
                            <img src="/assets/images/arrow_back_2.svg" alt="back-arrow" />
                        </Link>
                    </div>
                    <div className='bot-name-wrapper flex justify-start items-start mt-[10px] sm:mt-[30px] sm:absolute sm:bottom-[-35px] pl-[40px] sm:pl-[0px]'>
                        <div className="img-ct w-[65px] h-[65px] sm:w-[120px] sm:h-[120px] overflow-hidden rounded-[50%]">
                            <img src={botData?.data?.data[0]?.attributes?.ProfileImage?.data?.attributes
                            ?.url ? "https://studio.brainstormer.io"+botData?.data?.data[0]?.attributes?.ProfileImage?.data?.attributes
                            ?.url:'/assets/images/app_dark_img.png'} alt="bot-img" className='w-full h-full object-cover' /> 
                        </div>
                        <div className='bot-name-ct ml-3 sm:mt-[15px] flex justify-between items-start max-w-[75%]'>
                            <div className='mr-2 sm:mr-3 max-w-fit'>
                                <h1 className='text-[#252525] text-[22px] sm:text-[24px] font-bold leading-[24px] sm:leading-[34px]'>{botData && botData?.data?.data[0]?.attributes?.Name}</h1>
                                {botData?.data?.data[0]?.attributes?.creator?.data?.attributes?.Name ? <span className='text-[14px] sm:text-[16px] text-[#252525] font-semibold'>by {botData?.data?.data[0]?.attributes?.creator?.data?.attributes?.Name}</span>:null}
                                {/* <span className='rating-tag bg-[#F8B93F] text-[#fff] px-[4px] py-[1px] rounded-[3px] text-[12px] flex w-fit mt-2'>4.5 <img src="/assets/images/start-white.svg" alt="rating star" /></span> */}
                            </div>
                            <div className='ml-1 w-[75px] sm:w-[100px] h-[45px] relative mt-[4px] sm:mt-[7px]'>
                                <span className="price-tag bg-[#ddedda] text-[#74C265] px-[8px] rounded-[10px] text-[14px] inline-block">
                                    Free
                                </span>
                                {/* <a className='absolute bottom-0 right-0 cursor-pointer'>
                                    <img src="/assets/images/ic_baseline-share.svg" alt="share-icon" />
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bot-details-wrapper mt-[20px] sm:mt-[55px] px-3 sm:px-6 w-full pb-[20px]">
                    <p className='bot-details-para text-[16px] text-[#5A5A61] leading-[20px]'>{botData && botData?.data?.data[0]?.attributes?.Description}</p>
                    {botData?.data?.data[0].attributes?.tags?.data.length != 0 ?
                        <div className="detail-tag-ct flex mt-[30px] mb-[10px] flex flex-wrap">
                            {botData?.data?.data[0].attributes.tags.data.map((item,indx)=>
                                <span className='mb-[14px] uppercase mr-[15px] details-tag bg-[#F5F5F5] text-[#7C7C85] text-[14px] rounded-[10px] px-[8px] py-[1px] flex w-fit'>{item.attributes.Name}</span>
                            )}
                        </div>:null
                    } 
                </div>
                {botData?.data?.data[0].attributes.Question.length !=0 ?  
                    <div className="explore-bot-wrapper sm:px-1">
                        <h2 className='mx-3 lg:mx-4 text-[20px] font-bold px-0 sm:px-2 lg:px-1  pt-0 pb-0 text-[#252525]'>Ask {botData && botData?.data?.data[0]?.attributes?.Name}</h2>
                        <div id="explore_bot_slider" className="explore-bot-slider mx-3 lg:mx-3 explore-bot-content-ct bot-content-ct flex flex-nowrap w-full rounded-lg justify-start mt-4 sm:mt-2 pb-[10px] w-[98%] pr-4 relative">
                            {botData ? botData?.data?.data[0].attributes.Question.map((item,indx) =>
                                <Botquestions  data={botData?.data?.data[0]}  question={item} key={indx} createChannel={createChannel} imagePath={SENDBIRD_INFO.studioUrl} />
                            ):
                            <div className="loader-ct">
                                <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader'></img>
                            </div>
                            }
                        </div>
                    </div>:null
                }
                {/* <div className='chat-with-bot-wrapper mx-3 lg:mx-10 w-full my-[10px] sm:px-8 lg:px-2'>   
                    <h2 className='text-[20px] font-bold px-0  pt-0 pb-0 text-[#252525]'>Chat with the bot on</h2>
                    <div className="share-link-wrapper flex w-full my-[15px] flex-wrap">
                        <a className='share-link cursor-pointer flex justify-start items-center mr-[20px] my-[10px] bg-[#E0E0E0] px-[10px] py-[5px] rounded-[8px]'>
                            <img src="/assets/images/instagram-ic2.svg" alt="instagram icon" />
                            <span className='text-[#2C2D98] text-[16px] ml-[10px] font-bold'>Instagram</span>
                        </a>
                        <a className='share-link cursor-pointer flex justify-start items-center mr-[20px] my-[10px] bg-[#E0E0E0] px-[10px] py-[5px] rounded-[8px]'>
                            <img src="/assets/images/whatsapp-ic2.svg" alt="whatsapp icon" />
                            <span className='text-[#2C2D98] text-[16px] ml-[10px] font-bold'>Whatsapp</span>
                        </a>
                        <a className='share-link cursor-pointer flex justify-start items-center mr-[20px] my-[10px] bg-[#E0E0E0] px-[10px] py-[5px] rounded-[8px]'>
                            <img src="/assets/images/slack-ic2.svg" alt="slack icon" />
                            <span className='text-[#2C2D98] text-[16px] ml-[10px] font-bold'>Slack</span>
                        </a>
                    </div>
                </div> */}
                {botData?.data?.data[0].attributes?.bots?.data.length != 0 ?
                    <div className="recomended-bots-ct mx-3 sm:mx-0 sm:px-6 w-full my-[30px]">
                        <h2 className='text-[20px] font-bold px-0 sm:px-0  pt-0 pb-0 text-[#252525]'>Related Bots</h2>
                        <div id="related_bot_slider" className="related-bot-slider recomended-bot-slider recomended-bot-content-ct bot-content-ct flex flex-nowrap w-full rounded-lg justify-start mt-4 sm:mt-2 pb-[10px] w-[98%] pr-4 relative">
                            { botData?.data?.data[0].attributes?.bots?.data.map((item, indx)=>
                                <RecomendedCard data={item} key={indx}  imagePath={SENDBIRD_INFO.studioUrl} />
                            )}                    
                        </div>
                    </div>:null
                }
            </div>

            <div className="chat-now-btn-ct mx-3 sm:mx-0 sm:px-6 w-[94%] md:w-[100%] mb-[60px] sm:mb-[0px] pb-[140px] sm:pb-[12px]">
            {
                user ?
                <button className='start-convr-link w-full inline-flex cursor-pointer items-center justify-center px-4 py-[10px] text-lg font-medium text-center text-white bg-[#2C2D98] rounded-lg hover:bg-blue-800  dark:focus:ring-blue-800' onClick={()=>createChannel(botData?.data?.data[0]?.attributes.Name, botData?.data?.data[0]?.attributes.bot_id, "pdf", "chanel1", botData?.data?.data[0]?.attributes?.ProfileImage?.data?.attributes?.url ? SENDBIRD_INFO.studioUrl+botData?.data?.data[0]?.attributes?.ProfileImage?.data?.attributes?.url : 'https://static.sendbird.com/sample/cover/cover_14.jpg')}>Chat Now</button>
                :
                <button className='start-convr-link w-full inline-flex cursor-pointer items-center justify-center px-4 py-[10px] text-lg font-medium text-center text-white bg-[#2C2D98] rounded-lg hover:bg-blue-800  dark:focus:ring-blue-800' onClick={()=>createNewChannel()}>Sign Up</button>
            }
            </div>
            {/* <Mobfooter handleLoadMemberSelectionList={handleLoadMemberSelectionList} HandleHomeBtn={HandleBackBtn} HandleLibBtn={HandleLibBtn}/> */}
        </div>:!user && !loading  && !authLoading? <p className='px-3 sm:px-[24px] py-5 font-semibold'>You do not currently have access to this bot, please contact us for further assistance.</p>: null
    }
    {loading ? 
        <div className="loader-ct mt-[0px] fixed sm:absolute left-0 top-0 z-50 flex-1">
            <img src='/assets/images/loader3.gif' width={30} height={30} alt='loader' className="mt-[-40px]"></img>
        </div>:null}
   </div>
  )
}

export default Botprofile