import React from 'react'
import { json, useNavigate,BrowserRouter as Router,} from "react-router-dom";
import { useState, useEffect } from 'react';

const RecomendedCard = ({data, createChannel,imagePath}) => {

  const navigate = useNavigate();

    function openRecomendedBot(slug){
        navigate(`/botprofile/${slug}`);
    }

    function truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength - 1)+'...';
      } else {
        return text;
      }
    }
    
  return (
    <div className="bg-[#fff] recomended-card channel-card flex items-start p-3 border rounded-md w-auto h-auto mx-0 sm:ml-0 sm:mr-3  my-2 md:my-4 flex-col relative">
    
        <div className="img-ct top-sec flex flex-row items-center w-full">
            <div className="img-content-ct relative">
                <div className="img-innet-ct">
                    <img className=" mb-3 rounded-full shadow-lg" src={data?.attributes?.ProfileImage?.data!=null ? imagePath+data?.attributes?.ProfileImage?.data.attributes.url : "/assets/images/app_dark_img.png"} alt="Bonnie image"/>
                </div>
                {/* <span className="rating-tag absolute border border-1 border-[#F5BB4B] px-1 py-[0px]
                text-[11px] text-[#F5BB4B] rounded-[4px] bottom-0 right-[-10px] bg-[#fff] font-bold">4.5</span> */}
            </div>
            <h3 className="ml-[10px] text-[#252525] md:text-lg font-bold text-left break-word cursor-pointer" onClick={()=>openRecomendedBot(data?.attributes.Slug)}>{data?.attributes.Name}</h3>
        </div>
      
        <div className="content-ct mt-3 h-full flex flex-col justify-between">
            <p className='description text-left font-semibold text-sm text-gray-400'>{truncateText(data?.attributes?.Description, 115)}</p>
            {data?.attributes?.tags?.data.length > 0 ? 
              <div className='flex text-gray-400'>
            
                {data?.attributes?.tags?.data?.map((data) =>
                    <div className="ml-2 tagTitle inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full bg-white text-gray-400 border">
                      {data?.attributes?.Name}
                  </div>
                )
                }
              
              </div>:
              null  
            }
        </div>
    </div>
  )
}

export default RecomendedCard