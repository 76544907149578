import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { chart as chartJS, BarElement, Colors} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { Doughnut } from "react-chartjs-2";

const AccountDashboard = () => {

function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

const data = {
    labels: labels,
    datasets: [{
      axis: 'y',
      label: ' Data', 
      data: [85, 70, 80, 81, 56, 78, 40, 45, 59, 70, 68, 70],
      fill: false,
      backgroundColor: [
        '#696AAA',
    ],
    borderColor: [
      '#696AAA',
    ],
    borderWidth: 1,
    barPercentage: 0.1,
    categoryPercentage: 0.1,
    barThickness: 10,
    maxBarThickness: 10,
    minBarLength: 2,

  }]
};

const options ={
    indexAxis: isMobileDevice() ? 'x':'y',
    gridLines: {
        display:false,
    },
    scales: {
        x: {
            // stacked: true,
            barPercentage: 0, // Adjust this value to decrease or increase the width of the bars
            categoryPercentage: 0, // Adjust this value to decrease or increase the spacing between the bars
            grid: {
                display: false
            }
        },
        y: {
            // stacked: true,
            barPercentage: 0, // Adjust this value to decrease or increase the width of the bars
            categoryPercentage: 0, // Adjust this value to decrease or increase the spacing between the bars
            grid: {
                display: false
            },
        }
    },
}

const storagepercentage_data = {
    labels: ["Full", "Space"],
    datasets: [
      {
        label: "",
        fill: false,
        lineTension: 0.0,
        // backgroundColor: "rgba(75,192,192,0.4)",
        backgroundColor: ["#DF6E34", "#E6E6E6"],
        // borderColor: "#56d7a3",
        // borderColor: "rgba(75,192,192,1)",
        // borderCapStyle: "butt",
        // borderDash: [],
        // borderDashOffset: 0.0,
        // borderJoinStyle: "miter",
        // pointBorderColor: "rgba(75,192,192,1)",
        // pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        // pointHoverRadius: 5,
        // pointHoverBackgroundColor: "rgba(75,192,192,1)",
        // pointHoverBorderColor: "rgba(220,220,220,1)",
        // pointHoverBorderWidth: 2,
        // pointRadius: 1,
        // pointHitRadius: 10,
        data: [100 - 40, 40]
      }
    ]
  };

const storagepercentage_options={
    legend: {
      display: false
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    },
    cutout: isMobileDevice() ? 60 : 65,
    centerText: {
      color: "#fe8a5d",
      value: 340,
      fontSizeAdjust: 0.2 // 20% larger of the default size
    }
};

const storagedata = {
    labels: labels,
    datasets: [{
      label: ' Data', 
      axis: 'x',
      data: [85, 70, 80, 81, 56, 78, 40, 45, 59, 70, 68, 70],
      fill: false,
      backgroundColor: [
        '#DF6E34',
    ],
    borderColor: [
      '#DF6E34',
    ],
    borderWidth: 1,
    barPercentage: 0.1,
    categoryPercentage: 0.1,
    barThickness: 10,
    maxBarThickness: 10,
    minBarLength: 2,

  }]
};

const storageoptions ={
    indexAxis: 'x',
    gridLines: {
        display:false,
    },
    scales: {
        x: {
            barPercentage: 0, // Adjust this value to decrease or increase the width of the bars
            categoryPercentage: 0, // Adjust this value to decrease or increase the spacing between the bars
            grid: {
                display: false
            },
            ticks: {
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45,
            }
        },
        y: {
            barPercentage: 0, // Adjust this value to decrease or increase the width of the bars
            categoryPercentage: 0, // Adjust this value to decrease or increase the spacing between the bars
            grid: {
                display: false
            },
        }
    },
}

  return (
    <div className='w-[100%] h-fit lg:w-[100%] bg-[#F5F5F5] sm:bg-[#fff] pb-[100px] sm:pb-[0px]'>
        <Helmet>
            <title>Brainstormer User Dashboard</title>
        </Helmet>
        <div className="myacount-wraaper">
            <div className="myaccount-inner">
                <div className="account-header px-3 lg:px-[30px] pb-[20px]">
                    <div className="breadcrumbs-ct hidden sm:block py-2">
                        <ul className='flex items-center'>
                        <li className='text-[14px] list-none text-[#5A5A61] mr-[7px] font-semibold cursor-pointer'><Link to={'/'}>Home</Link></li>/
                            <li className='text-[14px] list-none text-[#5A5A61] mx-[7px] font-semibold cursor-pointer'><Link to={'/account'}>My Profile</Link></li>/
                            <li className='text-[14px] list-none text-[#252525] mx-[7px] font-semibold'>Dashboard</li>
                        </ul>
                    </div>
                    <div className="mob-heading flex justify-start items-center my-[14px] sm:hidden">
                        <a className="ml-[-10px]" href="/account"><img src="/assets/images/leftArrow.svg" alt="left-arrow-icon" /></a>
                        <h2 className='font-bold text-[20px]'>Dashboard</h2>
                    </div>
                    <h2 className='text-[#252525] text-[17px] font-bold mt-[10px] mb-[10px] px-[4px] sm:px-0'>My Subscription</h2>
                    <div className="creator-ct flex justify-between items-center my-[10px] px-[10px] sm:px-[24px] py-[15px] sm:py-[15px] bg-[#F5F5F5] rounded-[6px] border border-[#d1d1d1]">
                        <div className="content-ct">
                            <h2 className='text-[16px] text-[#252525] font-bold block'>Basic Plan</h2>
                            <p className='text-[#252525] m-0 mt-[4px] text-[14px] hidden lg:flex'>500 tokens <img src="/assets/images/dot_2.svg" alt="dot" className='mx-[8px]'/> 100 GB </p>
                        </div>
                        <div className="button-ct">
                            <button className='text-[#fff] bg-[#2C2D98] h-[35px] flex justify-center items-center hover:bg-opacity-75 w-[150px] text-[16px] px-[0px] rounded-[6px] flex font-semibold'>Upgrade</button>
                        </div>
                    </div>
                    <div className='token-progress flex flex-col mt-[30px] mb-[20px]'>
                        <h2 className='text-[#252525] font-bold self-start mb-[8px]'>This month’s run</h2>
                        <div className="w-full bg-gray-200 rounded-[2px] dark:bg-[#F5F5F5]">
                            <div className="w-[45%] bg-[#9C9DC9] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-[2px]"> 45%</div>
                        </div>
                        <span className='text-[#5A5A61] text-[14px] font-semibold self-end'>500 tokens</span>
                    </div>
                    <div className="chart-one-wrapper bg-[#fff] mb-[20px] lg:mb-[10px] lg:bg-[#F5F5F5] h-auto flex justify-between items-start relative p-[10px]">
                        <div className="chart-one w-full">
                            <h2 className='font-bold text-[17px] p-[15px]'>Previous Data</h2>
                            <div className='h-auto lg:h-[350px]'>
                                <Bar data={data} options={options} />
                            </div>
                        </div>
                        <div className='dropdown-ct p-[15px] absolute top-[0px] right-[0px]'>
                            <select name="data-one" id="data_one" className='bg-[#F5F5F5] font-semibold'>
                                <option value="monthly">Monthly</option>
                                <option value="monthly">Yearly</option>
                            </select>
                        </div>
                    </div>
                    <div className="chart-two-wrapper my-[0px] lg:my-[20px] flex justify-between flex-col lg:flex-row">
                        <div className='w-full lg:w-[48%]'>
                            <h2 className='text-[#252525] text-[18px] mb-[15px] lg:mb-[0px] font-bold'>Storage</h2>
                            <div className="chart-two h-[230px] lg:h-[270px]  w-[100%] flex justify-center items-center bg-[#fff] lg:bg-[#F5F5F5] my-[2px] lg:my-[15px] px-[10px] py-[15px] rounded-[6px]">
                                <div className='w-[180px] lg:w-[50%] max-w-[200px]'>
                                    <Doughnut data={storagepercentage_data} options={storagepercentage_options} />
                                </div>
                                <div className='w-[50%] flex flex-col msx-[10px] lg:mx-[20px]'>
                                    <span className='font-bold text-[#252525] text-[18px]'>60% used</span>
                                    <span className='text-[#5A5A61] text-[16px] font-semibold'>124gb available</span>
                                </div>
                            </div>
                        </div>
                        <div className="chart-three w-full lg:w-[48%] h-[230px] lg:h-[270px] bg-[#fff] lg:bg-[#F5F5F5] my-[2px] lg:mt-[40px] lg:mb-[20px] px-[10px] py-[15px] rounded-[6px]">
                            <h2 className='font-bold text-[17px] p-[0px]'>Previous Data</h2>
                            <div className='w-full max-w-[450px] h-[270px]'>
                                <Bar data={storagedata} options={storageoptions} />   
                            </div>
                        </div>
                    </div>
                    <div className="top-bots-wrapper sm:mt-[60px] lg:mt-[0px]">
                        <h2 className='font-bold text-[20px] mb-[10px]'>Top Bots</h2>
                        <div className="top-bots-inner bg-[#F5F5F5] p-[0px] sm:p-[15px] flex justify-between flex-wrap rounded-[6px]">
                            <div className="top-bot-item bg-[#fff] p-[10px] flex flex-col sm:flex-row justify-start items-center w-[48%] sm:w-[48%] lg:w-[32%] rounded-[6px] my-[10px]">
                                <div className="img-ct">
                                    <img src="/assets/images/top-bot-img2.svg" alt="bot img" />
                                </div>
                                <div className="content-ct ml-[0px] mt-[12px] sm:mt-[0px] sm:ml-[15px]">
                                    <span className='token-count text-[#5A5A61] text-[14px] text-center sm:text-left block'>60 tokens</span>
                                    <h3 className='bot-name text-[#252525] text-[18px] font-bold'>People Pulse</h3>
                                </div>
                            </div>
                            <div className="top-bot-item bg-[#fff] p-[10px] flex flex-col sm:flex-row justify-start items-center w-[48%] sm:w-[48%] lg:w-[32%] rounded-[6px] my-[10px]">
                                <div className="img-ct">
                                    <img src="/assets/images/top-bot-img2.svg" alt="bot img" />
                                </div>
                                <div className="content-ct ml-[0px] mt-[12px] sm:mt-[0px] sm:ml-[15px]">
                                    <span className='token-count text-[#5A5A61] text-[14px] text-center sm:text-left block'>60 tokens</span>
                                    <h3 className='bot-name text-[#252525] text-[18px] font-bold'>People Pulse</h3>
                                </div>
                            </div>
                            <div className="top-bot-item bg-[#fff] p-[10px] flex flex-col sm:flex-row justify-start items-center w-[48%] sm:w-[48%] lg:w-[32%] rounded-[6px] my-[10px]">
                                <div className="img-ct">
                                    <img src="/assets/images/top-bot-img2.svg" alt="bot img" />
                                </div>
                                <div className="content-ct ml-[0px] mt-[12px] sm:mt-[0px] sm:ml-[15px]">
                                    <span className='token-count text-[#5A5A61] text-[14px] text-center sm:text-left block'>60 tokens</span>
                                    <h3 className='bot-name text-[#252525] text-[18px] font-bold'>People Pulse</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
  )
}

export default AccountDashboard