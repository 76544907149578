import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";

const Feedback = () => {
    const [user, loading, error] = useAuthState(auth);
  return (
    <div className='w-[100%] h-fit lg:w-[100%] bg-[#F5F5F5] sm:bg-[#fff] pb-[100px] sm:pb-[0px]'>
        <Helmet>
            <title>Brainstormer User Feedback</title>
        </Helmet>
        {user?
        <div className="myacount-wraaper">
            <div className="myaccount-inner">
                <div className="account-header  px-3 lg:px-[30px] pb-[20px]">
                    <div className="breadcrumbs-ct hidden sm:block py-2">
                        <ul className='flex items-center'>
                        <li className='text-[14px] list-none text-[#5A5A61] mr-[7px] font-semibold cursor-pointer'><Link to={'/'}>Home</Link></li>/
                            <li className='text-[14px] list-none text-[#5A5A61] mx-[7px] font-semibold cursor-pointer'><Link to={'/account'}>My Profile</Link></li>/
                            <li className='text-[14px] list-none text-[#252525] mx-[7px] font-semibold'>Feedback</li>
                        </ul>
                    </div>
                    <div className="mob-heading flex justify-start items-center my-[14px] sm:hidden">
                        <Link className="ml-[-10px]" to="/account"><img src="/assets/images/leftArrow.svg" alt="left-arrow-icon" /></Link>
                        <h2 className='font-bold text-[20px]'>Feedback</h2>
                    </div>
                    <div className="mx-0 lg:mx-0 w-full md:w-full mb-[60px] sm:mb-[0px] pb-[140px] sm:pb-[0px] mt-[20px]"><a onClick={()=>{window.Usersnap.logEvent('feedback_submit')}} className="max-w-[180px] w-full h-[48px]  cursor-pointer underline py-[5px] text-md font-medium text-center text-[#2C2D98] rounded-lg mt-[15px]">Submit Feedback</a></div>
                </div>
            </div>
        </div>:<p className='m-[15px] sm:m-[25px] text-[#5a5a61] font-semibold'>You are not logged In! Please <Link to="/login" className='text-[#2C2D98] font-semibold underline'>Login</Link> and try again</p>}
    </div>
  )
}

export default Feedback