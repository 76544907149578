import React, {useEffect, useState} from 'react';
import { signInWithEmailAndPassword,signOut   } from 'firebase/auth';
import { auth,signInWithGoogle } from '../firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { analytic } from '../utils/Analytics';

const Logout = () => {
    const [user, loading, error] = useAuthState(auth);
    const [updateUser,setUpdatedUserData] = useState(false)
    useEffect(()=>{
        if(user){
            console.log(user,"user")
            const data = {
                "uid":user.uid,
                "authentication_method":null,
            }
            analytic("logout",data,null)
            setUpdatedUserData(true)
        }
        
    },[user])

    const navigate = useNavigate();
    if(updateUser){
        signOut(auth).then(() => {
        // Sign-out successful.
            navigate("/login");
            console.log("Signed out successfully")
            localStorage.removeItem('appPopup');
        }).catch((error) => {
        // An error happened.
        });
    }
   
 
    return(
        <>
        <h4>Loging out..</h4>
        </>
    )
}
 
export default Logout