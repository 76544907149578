import React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { json, useNavigate,BrowserRouter as Router} from "react-router-dom";
import Notiflix from 'notiflix';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const CategoryCard = ({data, createChannel,imagePath}) => {
const navigate = useNavigate();
const [user] = useAuthState(auth);
const location = useLocation();

const handleClick = (id) =>{
    navigate('/library/category/'+id);
    setTimeout(()=>{
      Notiflix.Loading.remove();
    },[1000]);
}

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 1)+'...';
  } else {
    return text;
  }
}

const [ppState,setPpstate] = useState(false);
const [deviceType, setDeviceType] = useState('');
const [browserType, setBrowserType] = useState('');

useEffect(()=>{
    if((("standalone" in window.navigator) && !window.navigator.standalone) || ( !window.matchMedia('(display-mode: standalone)').matches)){
        setPpstate(true)
    }
    const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) {
        setDeviceType('Android');
    } 
    else if (/iPhone/i.test(userAgent)) {
        setDeviceType('IOS');
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('chrome') > -1) {
            setBrowserType('ios-chrome');
        }  else if (userAgent.indexOf('safari') > -1) {
            setBrowserType('ios-safari');
        } 
    } else if (/Windows Phone/i.test(userAgent)) {
        setDeviceType('Windows Phone');
    } else {
        setDeviceType('Other');
    }
},[])
  
  return (
    <div className={user ? "w-[47%] bg-[#fff] channel-card category-card flex items-start p-5 border rounded-md h-auto mx-0 sm:ml-2 sm:mr-2 my-2 md:my-4 flex-col relative": location.pathname.split('/').slice(1) =='' ? "!w-[31%] bg-[#fff] channel-card category-card flex items-start p-5 border rounded-md h-auto mx-0 sm:ml-2 sm:mr-[.6%] my-2 md:my-4 flex-col relative" : "w-[32%] bg-[#fff] channel-card category-card flex items-start p-5 border rounded-md h-auto mx-0 sm:ml-2 sm:mr-[.5%] my-2 md:my-4 flex-col relative"}>
    
        <div className="img-ct top-sec flex flex-row items-center cursor-pointer sm:w-[100%]" onClick={()=>handleClick(data?.id)}>
            <div className="img-innet-ct">
                 <img className=" mb-3 rounded-full shadow-lg" src={data?.attributes?.ProfileImage?.data!=null ? imagePath+data?.attributes?.ProfileImage?.data.attributes.url : "/assets/images/app_dark_img.png"} alt="Bonnie image" loading="lazy"/>
            </div>
            <h3 className={user && location.pathname.split('/').slice(1) =='library' ? 'ml-3 text-[#2C2D98] md:text-lg font-bold text-left sm:max-w-[82%]' : user ? `ml-3 text-[#2C2D98] md:text-lg font-bold text-left sm:max-w-[75%]` : `ml-3 text-[#2C2D98] md:text-lg font-bold text-left sm:max-w-[85%]`}>{data?.attributes.Name}</h3>
        </div>
      
        <div className="content-ct mt-2 sm:mt-4 h-full flex flex-col justify-between">
            <p className='description text-left font-semibold text-sm text-gray-400'>{data && (deviceType == "IOS" || deviceType == "Android" || deviceType == "Windows Phone") ? truncateText(data?.attributes?.Description, 150) : truncateText(data?.attributes?.Description, 230)}</p>
            {data?.attributes?.tags?.data.length > 0 ? 
              <div className='flex text-gray-400'>
            
                {data?.attributes?.tags?.data?.map((data) =>
                    <div className="ml-2 tagTitle inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full bg-white text-gray-400 border">
                      {data?.attributes?.Name}
                  </div>
                )
                }
              
              </div>:
              null  
            }
            <div className="flex mt-4 space-x-3 md:mt-4 w-full ">
                <a onClick={()=>handleClick(data?.id)} className="start-convr-link w-full inline-flex cursor-pointer items-center justify-center px-4 py-3 text-xs font-medium text-center text-white bg-[#2C2D98] rounded-lg hover:bg-blue-800  dark:focus:ring-blue-800">Explore 
                </a>
            </div>
        </div>
    </div>
  )
}

export default CategoryCard