import React from 'react'
import { json, useNavigate,BrowserRouter as Router,} from "react-router-dom";
import { useState, useEffect } from 'react';

const FeaturedCard = ({featuredBot,creator}) => {

    const navigate = useNavigate();

    function openFeatureBot(name){
        navigate(`/botprofile/${name}`);
    }

    function openFeatureCreator(name){
        navigate(`/creator-profile/${name}`);
    }

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
          return text.substring(0, maxLength - 1)+'...';
        } else {
          return text;
        }
      }

    const [ppState,setPpstate] = useState(false);
    const [deviceType, setDeviceType] = useState('');
    const [browserType, setBrowserType] = useState('');

    useEffect(()=>{
        if((("standalone" in window.navigator) && !window.navigator.standalone) || ( !window.matchMedia('(display-mode: standalone)').matches)){
            setPpstate(true)
        }
        const userAgent = navigator.userAgent;
        if (/Android/i.test(userAgent)) {
            setDeviceType('Android');
        } 
        else if (/iPhone/i.test(userAgent)) {
            setDeviceType('IOS');
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf('chrome') > -1) {
                setBrowserType('ios-chrome');
            }  else if (userAgent.indexOf('safari') > -1) {
                setBrowserType('ios-safari');
            } 
        } else if (/Windows Phone/i.test(userAgent)) {
            setDeviceType('Windows Phone');
        } else {
            setDeviceType('Other');
        }
    },[])
  
  return (
    <div className="bg-[#fff] featured-card flex items-start p-3 border rounded-xl w-auto h-auto mx-0 sm:mx-2 my-2 md:my-4 flex-col relative mr-[15px]">
        <div className="creator-rating-ct flex justify-center items-center mx-[5px] absolute top-[8px] right-[5px]">
            <span className="creator-tag bg-[#F5F5F5] rounded-[10px] px-[10px] text-[#7C7C85] mx-[5px] text-[14px] uppercase">
               {creator}
            </span>
            <span className="rating">
                <img src="/assets/images/rating-star.svg" alt="rating" loading="lazy"/>
            </span>
        </div>
        <div className="img-ct top-sec flex flex-col w-full sm:flex-row items-center mt-[28px] sm:mt-[0px] mb-[6px] cursor-pointer" onClick={creator == "creator" ? ()=>openFeatureCreator(featuredBot?.attributes?.Slug) : ()=>openFeatureBot(featuredBot?.attributes?.Slug)}>
            <div className="img-content-ct relative mb-[8px] sm:mb-[0px]">
                <div className="img-innet-ct">
                    <img className=" mb-3 rounded-full shadow-lg" src={featuredBot?.attributes?.ProfileImage?.data?.attributes
                        ?.url ? "https://studio.brainstormer.io"+featuredBot?.attributes?.ProfileImage?.data?.attributes
                        ?.url:'assets/images/app_dark_img.png'} alt="Bonnie image" loading="lazy"/>
                </div>
                {featuredBot?.attributes?.bots?.data ? <span className="bot-no py-[1px] px-[5px] text-[12px] bg-[#F5F5F5] rounded-[10px] absolute bottom-[-10px] left-[0px] min-w-[48px] flex justify-center text-[#7C7C85]">{ featuredBot?.attributes?.bots?.data?.length+' Bots'}</span>:null}
            </div>
            <h3 className="sm:ml-3 text-[#252525] md:text-[18px] font-bold text-left whitespace-normal break-words text-center sm:text-left">{featuredBot && featuredBot?.attributes?.Name}</h3>
        </div>
    
        <div className="content-ct mt-2 sm:mt-3 h-full flex flex-col justify-between">
            <p className='description font-semibold text-[12px] text-[#5A5A61] mb-[20px] text-center sm:text-left'>{featuredBot && (deviceType == "IOS" || deviceType == "Android" || deviceType == "Windows Phone") ? truncateText(featuredBot?.attributes?.Description, 150) : truncateText(featuredBot?.attributes?.Description, 230)}</p>
            <div className="flex justify-center absolute bottom-[-17px] left-[42%]">
                {creator == "creator" ? 
                <a  className="start-convr-link w-[34px] h-[34px] inline-flex cursor-pointer items-center justify-center text-xs font-medium text-center text-white bg-[#E3E3E3] rounded-[20px]" onClick={()=>openFeatureCreator(featuredBot?.attributes?.Slug)}> 
                    <img className="" src="/assets/images/blue-arrow.svg" alt="card-arrow-icon" loading="lazy"/>
                </a>:creator == "bots" ? <a  className="start-convr-link w-[34px] h-[34px] inline-flex cursor-pointer items-center justify-center text-xs font-medium text-center text-white bg-[#E3E3E3] rounded-[20px]" onClick={()=>openFeatureBot(featuredBot?.attributes?.Slug)}> 
                    <img className="" src="/assets/images/blue-arrow.svg" alt="card-arrow-icon" loading="lazy"/>
                </a>:null}
            </div>
        </div>
    </div>
  )
}

export default FeaturedCard