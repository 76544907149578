import React from 'react';
import { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import ProfileIcon from './ProfileIcon';
import { json, useNavigate,BrowserRouter as Router,Link,useLocation} from "react-router-dom";
import { getMessaging, getToken } from 'firebase/messaging';
import SendBird from 'sendbird';
import { SENDBIRD_INFO } from '../constants/constants';



const Mobfooter = ({handleLoadMemberSelectionList,HandleHomeBtn, HandleLibBtn, handleSearchBtn}) => {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const [userOpen, setUserOpen] = useState(false);
    const [navOpen, setNavOpen] = useState(false);
    const location = useLocation();

    const onNotification =() => {   
        const messaging = getMessaging();

        const appId = SENDBIRD_INFO.appId;
        const vapidKey = SENDBIRD_INFO.vapid_id;
        console.log('hi');
        const sbb = new SendBird({appId});
        console.log(user.uid);
        sbb.connect(user.uid, user.displayName, (user, error) => {

        Notification.requestPermission().then(permission => {

            if (permission === 'granted') {

                getToken(messaging, {vapidKey: vapidKey})
                .then(currentToken => {
                if (currentToken) {
                    sbb.registerGCMPushTokenForCurrentUser(currentToken, (response, error) => {
                    if(error) console.log(error);
                    console.log("Token Registered:", currentToken)
                    });
                }
                })
                .catch(err => {
                console.log('An error occurred while retrieving a token. ', err);
                });
            } else {
                getToken(messaging, {vapidKey: vapidKey})
                .then(currentToken => {
                if (currentToken) {
                    sbb.registerGCMPushTokenForCurrentUser(currentToken, (response, error) => {
                    if(error) console.log(error);
                    console.log("Token Registered:", currentToken)
                    setUserOpen(!userOpen)
                    navOpen && setNavOpen(!navOpen)
                    });
                }
                })
                .catch(err => {
                console.log('An error occurred while retrieving a token. ', err);
                });
            console.log('Unable to get permission to notify.');
            }
        })
        })
    };
    
    function gotoLogin(){
        navigate('/login');
    }


  return (
    <div className='mob-footer bg-[#fff] fixed bottom-0 sm:hidden'>
        <div className="inner-ct flex justify-between items-center p-3">
            <div onClick={HandleHomeBtn} >
                <div className="icon-ct flex flex-col justify-center items-center mt-[4px]">
                    <div className="img-ct">
                    {location.pathname.split('/')[1] ==''?
                        <svg width="26" height="26" strokeWidth="2" viewBox="0 0 24 24" stroke='#2C2D98' fill="#2C2D98" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z"/>
                        </svg>:
                        <svg width="26" height="26" strokeWidth="2" viewBox="0 0 24 24" stroke='#616161' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.13478 20.7733V17.7156C9.13478 16.9351 9.77217 16.3023 10.5584 16.3023H13.4326C13.8102 16.3023 14.1723 16.4512 14.4393 16.7163C14.7063 16.9813 14.8563 17.3408 14.8563 17.7156V20.7733C14.8539 21.0978 14.9821 21.4099 15.2124 21.6402C15.4427 21.8705 15.7561 22 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0008C21.1356 20.3588 21.5 19.487 21.5 18.5778V9.86686C21.5 9.13246 21.1721 8.43584 20.6046 7.96467L13.934 2.67587C12.7737 1.74856 11.1111 1.7785 9.98539 2.74698L3.46701 7.96467C2.87274 8.42195 2.51755 9.12064 2.5 9.86686V18.5689C2.5 20.4639 4.04738 22 5.95617 22H7.87229C8.55123 22 9.103 21.4562 9.10792 20.7822L9.13478 20.7733Z"/>
                        </svg>}
                    </div>
                    <span className={`${location.pathname.split('/')[1] ==''? 'text-[#2C2D98]':'text-[#616161]'} menu-name text-[12px] block mt-[12px] font-semibold`}>Home</span>
                </div>
            </div>
            <div onClick={HandleLibBtn} >
                <div className="icon-ct flex flex-col justify-center items-center mt-[4px]">
                    <div className="img-ct">
                    {location.pathname.split('/')[1] =='library'?
                        <svg width="26" height="26" strokeWidth="2" viewBox="0 0 24 24" stroke='#2C2D98' fill="#2C2D98" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 9.4985H4V4H9.5V9.4985ZM19.9985 9.4985H14.5V4H19.9985V9.4985ZM9.5 19.9985H4V14.4985H9.5V19.9985ZM19.9985 19.9985H14.5V14.4985H19.9985V19.9985Z"/>
                        </svg>:
                        <svg width="26" height="26" strokeWidth="2" viewBox="0 0 24 24" stroke='#616161' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.5 9.4985H4V4H9.5V9.4985ZM19.9985 9.4985H14.5V4H19.9985V9.4985ZM9.5 19.9985H4V14.4985H9.5V19.9985ZM19.9985 19.9985H14.5V14.4985H19.9985V19.9985Z"/>
                        </svg>}
                    </div>
                    <span className={`${location.pathname.split('/')[1] =='library'? 'text-[#2C2D98]':'text-[#616161]'} menu-name text-[12px] block mt-[12px] font-semibold`}>Library</span>
                </div>
            </div>
            {/* <a onClick={() => user ? (handleLoadMemberSelectionList()) : (navigate("/login"))} >
                <div className="icon-ct flex flex-col justify-center items-center relative w-[67px] ml-[8px]" >
                    <div className="btn-ct w-[67px] h-[67px] bg-[#2c2d98] flex justify-center items-center absolute top-[-40px] left-[0px] rounded-[10px]">
                        <span className='text-[40px] text-[#fff] h-[67px]'>+</span>
                    </div>
                    <span className='menu-name text-[12px] text-[#616161] block mt-[35px]'>Create</span>
                </div>
            </a> */}
             {user ? 
                <div className='flex flex-col items-center'>
                <a onClick={()=>{navigate("/chat");}} className='w-[40px] h-[40px] bg-[#2c2d98] flex justify-center items-center rounded-[50%] mb-[4px]'>
                    <img  className="back-arrow lg:mx-0 " src="/assets/images/chat-icon-line.svg" alt="brainstromapp" width={24} height={24} />
                </a>
                <span className={`${location.pathname.split('/')[1] =='chat'? 'text-[#2C2D98]':'text-[#616161]'} menu-name text-[12px] block mt-[0px] font-semibold`}>Storms</span>
                </div>
                :
                <div className='flex flex-col items-center'>
                    <a onClick={()=>{navigate("/login");}} className='w-[40px] h-[40px] bg-[#2c2d98] flex justify-center items-center rounded-[50%] mb-[4px]'> 
                        <img  className="back-arrow lg:mx-0 " src="/assets/images/chat-icon-line.svg" alt="brainstromapp" width={24} height={24} />
                    </a>
                    <span className={`${location.pathname.split('/')[1] =='chat'? 'text-[#2C2D98]':'text-[#616161]'} menu-name text-[12px] block mt-[0px] font-semibold`}>Storms</span>
                </div>
             }  
            <div onClick={() => user ? (handleLoadMemberSelectionList()) : (navigate("/login"))}>
                <div className="icon-ct flex flex-col justify-center items-center mt-[5px]">
                    <div className="img-ct create-icon-ct ml-[10px]">
                    { <svg width="26" height="26" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4808 10.4343L3.40584 20.8491C3.35325 20.9225 3.27509 20.9735 3.18674 20.9922C3.0984 21.0109 3.00626 20.9958 2.92846 20.9499C2.85067 20.9041 2.79285 20.8308 2.76637 20.7445C2.7399 20.6582 2.74669 20.565 2.78542 20.4835L6.30102 13.082L1.42435 13.1055C1.35529 13.1177 1.28417 13.1095 1.21967 13.082C1.15517 13.0545 1.10006 13.0088 1.06106 12.9505C1.02206 12.8923 1.00085 12.8239 1.00003 12.7538C0.999197 12.6836 1.01878 12.6148 1.0564 12.5556L9.29609 1.16737C9.34408 1.09198 9.41831 1.03703 9.50443 1.01315C9.59056 0.989264 9.68248 0.99813 9.76245 1.03804C9.84242 1.07794 9.90477 1.14606 9.93748 1.22924C9.97018 1.31241 9.9709 1.40476 9.93952 1.48844L6.30102 9.87049H13.1059C13.1776 9.85368 13.2528 9.85917 13.3213 9.88623C13.3898 9.91329 13.4484 9.96062 13.4892 10.0219C13.5301 10.0832 13.5512 10.1555 13.5499 10.2291C13.5485 10.3027 13.5239 10.3746 13.4808 10.4343Z" strokeWidth="1.5"/>
                        <path d="M15.1533 5H23.1533"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19.1533 9L19.1533 1"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    }
                    </div>
                    <span className='menu-name text-[12px] text-[#616161] block mt-[12px] font-semibold'>Create</span>
                </div>
            </div>
            {user ? 
                <Link to="/account" >
                    <div className="icon-ct flex flex-col justify-center items-center mt-[4px]">
                        <div className="img-ct">
                        {location.pathname.split('/')[1] =='account' ? 
                        <svg width="27" height="27" strokeWidth="2" viewBox="0 0 23 23" stroke='#2C2D98' fill="#2C2D98" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.55556 7.44444C8.55556 6.53092 8.91845 5.65481 9.56441 5.00885C10.2104 4.3629 11.0865 4 12 4C12.9135 4 13.7896 4.3629 14.4356 5.00885C15.0815 5.65481 15.4444 6.53092 15.4444 7.44444C15.4444 8.35797 15.0815 9.23408 14.4356 9.88004C13.7896 10.526 12.9135 10.8889 12 10.8889C11.0865 10.8889 10.2104 10.526 9.56441 9.88004C8.91845 9.23408 8.55556 8.35797 8.55556 7.44444ZM4.33429 16.4454C5.18862 15.5911 6.34735 15.1111 7.55556 15.1111H16.4444C17.6527 15.1111 18.8114 15.5911 19.6657 16.4454C20.52 17.2997 21 18.4585 21 19.6667C21 20.2855 20.7542 20.879 20.3166 21.3166C19.879 21.7542 19.2855 22 18.6667 22H5.33333C4.71449 22 4.121 21.7542 3.68342 21.3166C3.24583 20.879 3 20.2855 3 19.6667C3 18.4585 3.47996 17.2997 4.33429 16.4454Z" />
                        </svg>:
                        <svg width="27" height="27" strokeWidth="2" viewBox="0 0 23 23" stroke='#616161' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.55556 7.44444C8.55556 6.53092 8.91845 5.65481 9.56441 5.00885C10.2104 4.3629 11.0865 4 12 4C12.9135 4 13.7896 4.3629 14.4356 5.00885C15.0815 5.65481 15.4444 6.53092 15.4444 7.44444C15.4444 8.35797 15.0815 9.23408 14.4356 9.88004C13.7896 10.526 12.9135 10.8889 12 10.8889C11.0865 10.8889 10.2104 10.526 9.56441 9.88004C8.91845 9.23408 8.55556 8.35797 8.55556 7.44444ZM4.33429 16.4454C5.18862 15.5911 6.34735 15.1111 7.55556 15.1111H16.4444C17.6527 15.1111 18.8114 15.5911 19.6657 16.4454C20.52 17.2997 21 18.4585 21 19.6667C21 20.2855 20.7542 20.879 20.3166 21.3166C19.879 21.7542 19.2855 22 18.6667 22H5.33333C4.71449 22 4.121 21.7542 3.68342 21.3166C3.24583 20.879 3 20.2855 3 19.6667C3 18.4585 3.47996 17.2997 4.33429 16.4454Z" />
                        </svg>}
                        </div>
                        <span className={`${location.pathname.split('/')[1] =='account'? 'text-[#2C2D98]':'text-[#616161]'} menu-name text-[12px] block mt-[12px] font-semibold`}>My profile</span>
                    </div>
                </Link>: !loading ?
                <a onClick={gotoLogin}>
                    <div className="icon-ct flex flex-col justify-center items-center mt-[4px]">
                        <div className="img-ct">
                        <svg width="27" height="27" strokeWidth="2" viewBox="0 0 23 23" stroke='#616161' fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.55556 7.44444C8.55556 6.53092 8.91845 5.65481 9.56441 5.00885C10.2104 4.3629 11.0865 4 12 4C12.9135 4 13.7896 4.3629 14.4356 5.00885C15.0815 5.65481 15.4444 6.53092 15.4444 7.44444C15.4444 8.35797 15.0815 9.23408 14.4356 9.88004C13.7896 10.526 12.9135 10.8889 12 10.8889C11.0865 10.8889 10.2104 10.526 9.56441 9.88004C8.91845 9.23408 8.55556 8.35797 8.55556 7.44444ZM4.33429 16.4454C5.18862 15.5911 6.34735 15.1111 7.55556 15.1111H16.4444C17.6527 15.1111 18.8114 15.5911 19.6657 16.4454C20.52 17.2997 21 18.4585 21 19.6667C21 20.2855 20.7542 20.879 20.3166 21.3166C19.879 21.7542 19.2855 22 18.6667 22H5.33333C4.71449 22 4.121 21.7542 3.68342 21.3166C3.24583 20.879 3 20.2855 3 19.6667C3 18.4585 3.47996 17.2997 4.33429 16.4454Z" />
                        </svg>

                        </div>
                        <span className={`${location.pathname.split('/')[1] =='login'? 'text-[#2C2D98]':'text-[#616161]'} menu-name text-[12px] block mt-[12px] font-semibold`}>Login</span>
                    </div>
                </a> :null
            }
            <div className={`absolute top-[-90px] right-[0px] min-w-[200px] md:right-6  mt-2 origin-bottom-right bg-white rounded-md shadow-lg ring-1 ring-black z-20 ring-opacity-5 ${userOpen ? 'transition duration-500 ease-in-out transform opacity-100 scale-y-100' : 'transition duration-500 ease-in-out transform opacity-0 scale-y-0'}`}>
            <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <span className="block px-2 py-2 text-md text-black-700" role="menuitem">
                    <span className="flex flex-col">
                        <a className='hover:text-[#2C2D98] cursor-pointer'>
                            <span className='py-2 px-1 text-[#000] block user-name2'>Hi! {user?.displayName}</span>
                        </a>
                        <a className="hover:text-[#2C2D98]" href="/logout">
                            <span className='py-2 px-1 text-[#000]'>Logout</span>
                        </a>
                        {
                            "Notification" in window ? (Notification.permission !== "granted") ?  
                            <a onClick={onNotification} className="hover:text-[#2C2D98] cursor-pointer">
                                <span className='py-2 px-1 text-[#000]'>Allow Notification</span>
                            </a>:null
                                    : null
                        }
                    </span>
                </span>
            </div>
            <div className="flex hidden">
                <button className="text-gray-800 dark:text-gray-800 hover:text-gray-800 inline-flex items-center justify-center p-3 rounded-md focus:outline-none">
                    <svg width="20" height="20" fill="currentColor" className="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path></svg>
                </button>
            </div>
        </div>
            {/* {user ? <ProfileIcon userid={user.uid} username={user.displayName }/> : null }  */}
        </div>
    </div>
  )
}

export default Mobfooter