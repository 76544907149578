import React from 'react';
import { useState, useEffect } from 'react';
import { getMessaging, getToken } from 'firebase/messaging';
import SendBird from 'sendbird';
import { SENDBIRD_INFO } from '../constants/constants';
import { Link } from 'react-router-dom/dist';
import { useLocation } from "react-router-dom";

const ProfileIcon = ({userid,username}) => {

    const [userOpen, setUserOpen] = useState(false);
    const [navOpen, setNavOpen] = useState(false);

    const location = useLocation();

    const onNotification =() => {   
        const messaging = getMessaging();

        const appId = SENDBIRD_INFO.appId;
        const vapidKey = SENDBIRD_INFO.vapid_id;
        console.log('hi');
        const sbb = new SendBird({appId});
        console.log(userid);
        sbb.connect(userid, username, (user, error) => {

        Notification.requestPermission().then(permission => {

            if (permission === 'granted') {

                getToken(messaging, {vapidKey: vapidKey})
                .then(currentToken => {
                if (currentToken) {
                    sbb.registerGCMPushTokenForCurrentUser(currentToken, (response, error) => {
                    if(error) console.log(error);
                    console.log("Token Registered:", currentToken)
                    });
                }
                })
                .catch(err => {
                console.log('An error occurred while retrieving a token. ', err);
                });
            } else {
                getToken(messaging, {vapidKey: vapidKey})
                .then(currentToken => {
                if (currentToken) {
                    sbb.registerGCMPushTokenForCurrentUser(currentToken, (response, error) => {
                    if(error) console.log(error);
                    console.log("Token Registered:", currentToken)
                    setUserOpen(!userOpen)
                    navOpen && setNavOpen(!navOpen)
                    });
                }
                })
                .catch(err => {
                console.log('An error occurred while retrieving a token. ', err);
                });
            console.log('Unable to get permission to notify.');
            }
        })
        })
    };

return(
    <>
        <div className="main-link text-md text-white justify-center px-3 sm:px- cursor-pointer flex align-items-center" >
            <a className='flex justify-center items-center'>
            {location.pathname.split('/')[1] =='account' ? 
            <svg width="22" height="22" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.55556 6.64757C8.55556 5.73405 8.91845 4.85794 9.56441 4.21198C10.2104 3.56602 11.0865 3.20312 12 3.20312C12.9135 3.20312 13.7896 3.56602 14.4356 4.21198C15.0815 4.85794 15.4444 5.73405 15.4444 6.64757C15.4444 7.56109 15.0815 8.4372 14.4356 9.08316C13.7896 9.72912 12.9135 10.092 12 10.092C11.0865 10.092 10.2104 9.72912 9.56441 9.08316C8.91845 8.4372 8.55556 7.56109 8.55556 6.64757ZM4.33429 15.6485C5.18862 14.7942 6.34735 14.3142 7.55556 14.3142H16.4444C17.6527 14.3142 18.8114 14.7942 19.6657 15.6485C20.52 16.5029 21 17.6616 21 18.8698C21 19.4886 20.7542 20.0821 20.3166 20.5197C19.879 20.9573 19.2855 21.2031 18.6667 21.2031H5.33333C4.71449 21.2031 4.121 20.9573 3.68342 20.5197C3.24583 20.0821 3 19.4886 3 18.8698C3 17.6616 3.47996 16.5029 4.33429 15.6485Z" stroke="white" strokeWidth="2"/>
            </svg>:
            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.55556 6.64757C8.55556 5.73405 8.91845 4.85794 9.56441 4.21198C10.2104 3.56602 11.0865 3.20312 12 3.20312C12.9135 3.20312 13.7896 3.56602 14.4356 4.21198C15.0815 4.85794 15.4444 5.73405 15.4444 6.64757C15.4444 7.56109 15.0815 8.4372 14.4356 9.08316C13.7896 9.72912 12.9135 10.092 12 10.092C11.0865 10.092 10.2104 9.72912 9.56441 9.08316C8.91845 8.4372 8.55556 7.56109 8.55556 6.64757ZM4.33429 15.6485C5.18862 14.7942 6.34735 14.3142 7.55556 14.3142H16.4444C17.6527 14.3142 18.8114 14.7942 19.6657 15.6485C20.52 16.5029 21 17.6616 21 18.8698C21 19.4886 20.7542 20.0821 20.3166 20.5197C19.879 20.9573 19.2855 21.2031 18.6667 21.2031H5.33333C4.71449 21.2031 4.121 20.9573 3.68342 20.5197C3.24583 20.0821 3 19.4886 3 18.8698C3 17.6616 3.47996 16.5029 4.33429 15.6485Z" stroke="white" strokeWidth="2"/>
            </svg>}
            <span className='text-[17px] pl-[4px]'>My profile</span>
            </a>
        </div>
        <div className={`absolute top-[40px] right-6 min-w-[200px] md:right-6  mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black z-20 ring-opacity-5 ${userOpen ? 'transition duration-500 ease-in-out transform opacity-100 scale-y-100' : 'transition duration-500 ease-in-out transform opacity-0 scale-y-0'}`}>
            <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <span className="block px-2 py-2 text-md text-black-700" role="menuitem">
                    <span className="flex flex-col">
                        <a className='hover:text-[#2C2D98] cursor-pointer'>
                            <span className='py-2 px-1 text-[#000] block user-name2'>Hi! {username}</span>
                        </a>
                        <a className="hover:text-[#2C2D98]" href="/logout">
                            <span className='py-2 px-1 text-[#000]'>Logout</span>
                        </a>
                        {
                            "Notification" in window ? (Notification.permission !== "granted") ?  
                            <a onClick={onNotification} className="hover:text-[#2C2D98] cursor-pointer">
                                <span className='py-2 px-1 text-[#000]'>Allow Notification</span>
                            </a>:null
                                    : null
                        }
                    </span>
                </span>
            </div>
            <div className="flex hidden">
                <button className="text-gray-800 dark:text-gray-800 hover:text-gray-800 inline-flex items-center justify-center p-3 rounded-md focus:outline-none">
                    <svg width="20" height="20" fill="currentColor" className="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path></svg>
                </button>
            </div>
        </div>
    </>
)}

export default ProfileIcon